/*********************************** HEADER CSS *********************************************/
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 80px;

  /* background-color: #fcfcfc; */
  background-color: #f8f9fd;
  position: relative;
  z-index: 1;
  /* box-shadow: 0 5px 8px 0 rgba(89, 89, 89, 0.18); */
}
header img {
  cursor: pointer;
}
header .auth-con {
  display: flex;
  align-items: center;
  gap: 40px;
}
header .auth-con button.login {
  outline: none;
  border: none;
  background: none;
  font-family: "Nunito Sans", sans-serif;
  color: #262626;
  font-size: 16px;
  cursor: pointer;
}
header .auth-con button.signup {
  outline: none;
  border: none;
  background: none;
  font-family: "Nunito Sans", sans-serif;
  border: 1px solid #e0201c;
  padding: 0.6rem 1rem;
  border-radius: 4px;
  min-width: 11.75rem;
  color: #e0201c;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #e0201c;
    color: white;
  }
}
header .my-account-btn {
  outline: none;
  border: none;
  background: none;
  font-family: "Nunito Sans", sans-serif;
  border: 1px solid #e0201c;
  padding: 0.6rem 1rem;
  border-radius: 4px;
  min-width: 11.75rem;
  color: #e0201c;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #e0201c;
    color: white;
  }
}
.Navbar {
  display: flex;
  background-color: white;
  padding: 10px;
  cursor: pointer;
}
header .hamburger-mobile {
  display: none;
}
header .mobile-nav {
  display: none;
}
header .header_logo_link {
  text-decoration: none;
  color: #000;
}
.logo_heading{
  font-family: "poppins", sans-serif;
  font-weight: 600;
  font-size: 35px;
  letter-spacing: 0.1px;
    /* color: #1d3557; */
    color: #000;
  margin: 0;
  font-style: italic;
}
/*********************************** FOOTER CSS *********************************************/
footer {
}
footer .top {
  padding: 50px 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}
footer .top .left {
}
footer .top .left img {
  width: 100%;
}
footer .top .right {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  /* align-items: center; */
}
footer .top .right .one:first-child {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
footer .top .right .one {
  flex: 1;
}
footer .top .right .one .wrap {
  background: #f6fafe;
  padding: 16px;
  text-align: center;
}
footer .top .right .one .wrap h3 {
  margin: 0;
  /* font-family: "poppins", sans-serif; */
  font-size: 16px;
  /* color: #000; */
  margin-bottom: 16px;
  font-weight: 600;
  font-family: "Rubik", sans-serif;
  color: #1d3557;
}
footer .top .right .one .wrap button {
  border: none;
  outline: none;
  background: #e0201c;
  padding: 0.4rem 0.6rem;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #fff;
  border-radius: 4px;
  font-weight: 600;
}
footer .top .right .one h1 {
  font-size: 16px;
  /* font-family: "Nunito Sans", sans-serif;
  color: #000; */
  font-weight: 600;
  margin-bottom: 50px;
  font-family: "Rubik", sans-serif;
  color: #1d3557;
}
footer .top .right .one a {
  text-decoration: none;
}
footer .top .right .one p {
  margin: 0;
  color: #6d6875;
  margin-bottom: 16px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  cursor: pointer;
}

footer .bottom {
  display: flex;
  justify-content: space-between;
  background-color: #f6fafe;
  padding: 20px 80px;
}
footer .bottom p {
  margin: 0;
  /* color: #000000; */
  font-size: 16px;
  /* font-family: "Nunito Sans", sans-serif; */
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color: #1d3557;
}

/*********************************** LANDING PAGE CSS *********************************************/
.landingpage {
}
.landingpage .sec-one {
  padding: 72px 80px;
  /* background-color: #f6fafe; */
}
.landingpage .sec-one .containerr {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  /* align-items: center; */
}
.landingpage .sec-one .containerr .left {
  flex: 1;
}
.landingpage .sec-one .containerr .left .content {
  max-width: 520px;
}
.landingpage .sec-one .containerr .left h1 {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  color: #1d3557;
  font-size: 40px;
  line-height: 48px;
  font-weight: 600;
  /* padding-bottom: 20px; */
}
.landingpage .sec-one .containerr .left p {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: #6d6875;
  max-width: 350px;
  padding-bottom: 20px;
}
.landingpage .sec-one .containerr .left h1 span {
  color: #e0201c;
}
.landingpage .sec-one .containerr .right {
  flex: 1;
}
.landingpage .sec-one .containerr .right img {
  width: 100%;
}
.btn-comp {
  border: none;
  outline: none;
  background: transparent;
  padding: 0.7rem 1rem;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #e0201c;
  border: 1px solid #e0201c;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out,
    transform 0.2s ease-in-out, box-shadow 0.3s ease-in-out;

  &:hover {
    background-color: #e0201c;
    color: #fff;
    transform: scale(1.05);
    box-shadow: 0 4px 10px rgba(224, 32, 28, 0.4);
  }
}

.landingpage .sec-two {
  padding: 1.5rem 0px;
}
.landingpage .sec-two .content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}
.landingpage .sec-two .content .p1 {
  font-size: 14px;
  font-family: "poppins", sans-serif;
  color: #404040;
  margin: 0;
}
.landingpage .sec-two .content .p2 {
  font-size: 14px;
  font-family: "poppins", sans-serif;
  color: #404040;
  margin: 0;
}
.landingpage .sec-two .content h2 {
  font-size: 18px;
  font-family: "poppins", sans-serif;
  color: #404040;
  margin: 0;
  font-weight: 500;
}
.landingpage .sec-two .content .StarIcon {
  width: 160px;
  cursor: pointer;
}
.landingpage .sec-two .content .StarIcon path {
  /* fill: #00b67a; */
}
.landingpage .sec-two .content .TrustpilotIcon {
  width: 100px;
  cursor: pointer;
}

.landingpage .sec-three {
  padding: 72px 80px;
  /* background-color: #f6fafe; */
}
.landingpage .sec-three h1 {
  text-align: center;
  /* color: #404040; */
  font-weight: bold;
  /* font-family: "poppins", sans-serif; */
  font-size: 40px;
  margin-bottom: 50px;
  font-family: "Rubik", sans-serif;
  color: #1d3557;
}

.landingpage .sec-three .steps-con {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1001px;
  margin-inline: auto;
  margin-bottom: 50px;
  flex-wrap: wrap;
}
.landingpage .sec-three .steps-con .steps {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 324px;
  margin-inline: auto;
}
.landingpage .sec-three .steps-con .steps h3 {
  /* color: #262626;
  font-family: "poppins", sans-serif; */
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  text-align: center;
  font-family: "Rubik", sans-serif;
  color: #1d3557;
  min-height: 57.6px;
}
.landingpage .sec-three .steps-con .steps p {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #6d6875;
  text-align: center;
}

.landingpage .sec-three .btn-wrapper {
  text-align: center;
}

.landingpage .sec-four {
  padding: 72px 80px;
}
.landingpage .sec-four .containerr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.landingpage .sec-four .containerr .left {
  flex: 1;
}
.landingpage .sec-four .containerr .left img {
  width: 100%;
}
.landingpage .sec-four .containerr .right {
  flex: 1;
  padding-left: 80px;
}
.landingpage .sec-four .containerr .right .content {
}
.landingpage .sec-four .containerr .right .content .wrap {
  position: relative;
  margin-bottom: 25px;
}
.landingpage .sec-four .containerr .right .content .wrap h3 {
  margin: 0;
  font-size: 32px;
  font-weight: bold;
  color: #404040;
  font-family: "poppins", sans-serif;
  background-color: #f6fafe;
  padding: 16px;
  border-radius: 32px;
  text-align: center;
  position: relative;
}
.landingpage .sec-four .containerr .right .content .wrap p {
  color: #fff;
  background-color: #f98820;
  font-family: "Nunito Sans", sans-serif;
  font-size: 19px;
  font-weight: bold;
  text-align: center;
  margin: 0;
  color: #fff;
  border-radius: 20px;
  padding: 0 0.625rem;
  position: absolute;
  top: -18px;
  right: -5px;
}

.landingpage .sec-four .containerr .right .content .p1 {
  font-size: 18px;
  font-family: "Nunito Sans", sans-serif;
  color: #404040;
  margin: 0;
  text-align: center;
  margin-bottom: 25px;
}
.landingpage .sec-four .containerr .right .content .btn-wrapper {
  text-align: center;
}

.landingpage .sec-five {
  padding: 72px 80px;
  background-color: #fcfcfc;
}
.landingpage .sec-five .top-content {
  margin-bottom: 40px;
}
.landingpage .sec-five .top-content h1 {
  font-size: 40px;
  font-weight: bold;
  /* color: #404040;
  font-family: "poppins", sans-serif; */
  text-align: center;
  margin-bottom: 16px;
  font-family: "Rubik", sans-serif;
  color: #1d3557;
}
.landingpage .sec-five .top-content p {
  text-align: center;
  margin: 0;
  color: #6d6875;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
}
.landingpage .preview-container {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
.landingpage .preview-container .left {
  flex: 1;
}
.landingpage .preview-container .left .preview-buttons-con {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}
.landingpage .preview-container .left .preview-buttons-con .one {
  background-color: #fcfcfc;
  height: 4rem;
  border: 2px solid #bfbfbf;
  border-radius: 0.5rem;
  display: flex;
  gap: 15px;
  align-items: center;
  width: 45%;
  padding: 0.75rem 1.25rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.landingpage .preview-container .left .preview-buttons-con .one .img-con {
  background-color: #f2f2f2;
  padding: 8px;
  border-radius: 30px;
}
.landingpage .preview-container .left .preview-buttons-con .one .img-con img {
  width: 25px;
}
.landingpage
  .preview-container
  .left
  .preview-buttons-con
  .one
  .img-con.active {
  background-color: #a3ccf5;
}
.landingpage .preview-container .left .preview-buttons-con .one p {
  margin: 0;
  /* color: #262626;
  font-family: "Nunito Sans", sans-serif; */
  font-size: 16px;
  font-weight: 600;
  font-family: "Rubik", sans-serif;
  color: #1d3557;
}
.landingpage .preview-container .right {
  flex: 2;
}
.landingpage .preview-container .right .wrap {
  display: flex;
  gap: 40px;
  justify-content: space-between;
}
.landingpage .preview-container .right .wrap .left img {
  width: 100%;
}
.landingpage .preview-container .right .wrap .right {
  display: flex;
}
.landingpage .preview-container .right .wrap .right .content {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 20px;
  padding: 3.25rem 2.5rem;
  background-color: #f6fafe;
}
.landingpage .preview-container .right .wrap .right .content h1 {
  margin: 0;
  /* font-family: "poppins", sans-serif;
  color: #404040; */
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  font-family: "Rubik", sans-serif;
  color: #1d3557;
}
.landingpage .preview-container .right .wrap .right .content p {
  margin: 0;
  /* font-family: "Nunito Sans", sans-serif; */
  font-size: 18px;
  /* color: #404040; */
  font-size: 500;
  text-align: start;
  font-family: "Roboto", sans-serif;
  color: #6d6875;
}

.landingpage .sec-six {
  padding: 72px 80px;
}
.landingpage .sec-six .containerr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}
.landingpage .sec-six .containerr .left {
  flex: 1;
}
.landingpage .sec-six .containerr .left .content {
}
.landingpage .sec-six .containerr .left .content h1 {
  /* color: #404040;
  font-family: "poppins", sans-serif; */
  text-align: start;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 16px;
  font-family: "Rubik", sans-serif;
  color: #1d3557;
}
.landingpage .sec-six .containerr .left .content p {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #6d6875;
  margin-bottom: 16px;
}
.landingpage .sec-six .containerr .right {
  flex: 2;
  display: flex;
  gap: 20px;
  justify-content: center;
}
.landingpage .sec-six .containerr .right .one .cardd {
  margin-bottom: 26px;
}
.landingpage .sec-six .containerr .right .two .cardd {
  margin: 26px 0px;
}
.landingpage .sec-six .containerr .right .cardd {
  max-width: 25rem;
  width: auto;
  padding: 1.5rem;
  background-color: #fcfcfc;
  box-shadow: 0 5px 8px 0 rgba(89, 89, 89, 0.18);
  border-radius: 12px;
}
.landingpage .sec-six .containerr .right .cardd .wrap {
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
  align-items: center;
}
.landingpage .sec-six .containerr .right .cardd .wrap img {
}
.landingpage .sec-six .containerr .right .cardd .wrap h2 {
  /* color: #404040; */
  font-size: 18px;
  /* font-family: "Nunito Sans", sans-serif; */
  margin: 0;
  font-weight: 600;
  font-family: "Rubik", sans-serif;
  color: #1d3557;
}
.landingpage .sec-six .containerr .right .cardd p {
  color: #6d6875;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  margin: 0;
  font-weight: 400;
}
.landingpage .sec-seven {
  padding: 72px 80px;
  background-color: #e0201c;
}
.landingpage .sec-seven h1 {
  color: #fcfcfc;
  text-align: center;
  /* font-family: "poppins", sans-serif; */
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 40px;
  font-family: "Rubik", sans-serif;
}
.tabs-con.faq {
  max-width: 800px;
  margin-inline: auto;
}
.tabs-con.faq .btn-tabs-con {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.tabs-con.faq .btn-tabs-con p {
  color: #fff;
  padding: 6px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
}
.tabs-con.faq .btn-tabs-con p.active {
  background-color: #f27b79;
}
.tabs-con.faq .faq-con {
}
.tabs-con.faq .faq-con ul {
  padding: 0;
}
.tabs-con.faq .faq-con ul li::marker {
  display: none;
  color: transparent;
}
.tabs-con.faq .faq-con .two ol li::marker {
  color: #000;
}
.tabs-con.faq .faq-con .one {
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 20px;
  padding: 1.5rem 1.75rem;
}
.tabs-con.faq .faq-con .one .wrap {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 12px;
  /* margin-bottom: 20px; */
  cursor: pointer;
}
.tabs-con.faq .faq-con .one .two {
  margin-top: 20px;
  color: #6d6875;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.tabs-con.faq .faq-con .one .wrap p {
  margin: 0;
  /* color: #262626; */
  /* font-family: "Nunito Sans", sans-serif; */
  font-size: 16px;
  font-weight: 600;
  font-family: "Rubik", sans-serif;
  color: #1d3557;
}
.landingpage .sec-eight {
  padding: 72px 80px;
}
.landingpage .sec-eight .content {
  text-align: center;
}
.landingpage .sec-eight .content h1 {
  /* color: #404040; */
  text-align: center;
  /* font-family: "poppins", sans-serif; */
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 25px;
  font-family: "Rubik", sans-serif;
  color: #1d3557;
}
.landingpage .sec-eight .content p {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #6d6875;
  margin-bottom: 25px;
}

/*********************************** QRCREATOR CSS ***************************************/
.qr-creator-main {
  /* padding: 80px; */
  /* background-color: #f6fafe; */
}
.qr-creator-main .page1 {
}
.qr-creator-main .page1 .containerr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  margin-bottom: 40px;
}
.qr-creator-main .page1 .containerr .left {
  flex: 2;
  padding: 80px 20px 80px 80px;
  /* background-color: #f6fafe; */
}
.qr-creator-main .page1 .containerr .left .h1 {
  color: #262626;
  font-family: "Nunito Sans", sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}
.qr-creator-main .page1 .containerr .left .card-con {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: space-between;
}
.qr-creator-main .page1 .containerr .left .card-con .cardd {
  height: 5.125rem;
  padding: 1rem;
  background-color: #fcfcfc;
  border: 2px solid #bfbfbf;
  border-radius: 0.5rem;
  display: flex;
  gap: 12px;
  align-items: center;
  flex: 1 1 calc(50% - 25px);
  cursor: pointer;
  box-shadow: 0 5px 8px 0 rgba(89, 89, 89, 0.18);
}
.qr-creator-main .page1 .containerr .left .card-con .cardd .img-con {
  background-color: #f2f2f2;
  padding: 8px;
  border-radius: 30px;
}
.qr-creator-main .page1 .containerr .left .card-con .cardd.active {
  border: 1px solid #e0201c;
}
.qr-creator-main .page1 .containerr .left .card-con .cardd .img-con.active {
  background-color: #ed7171;
}
.qr-creator-main .page1 .containerr .left .card-con .cardd .img-con img {
  width: 25px;
  object-fit: contain;
}
.qr-creator-main .page1 .containerr .left .card-con .cardd .wrap {
}
.qr-creator-main .page1 .containerr .left .card-con .cardd .wrap h2 {
  font-size: 16px;
  color: #404040;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  margin: 0;
}
.qr-creator-main .page1 .containerr .left .card-con .cardd .wrap p {
  margin: 0;
  font-size: 16px;
  color: #8c8c8c;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 500;
}
.qr-creator-main .page1 .containerr .right {
  flex: 1;
  padding: 25px;
  background-color: #fff;
}
.qr-creator-main .page1 .containerr .right img {
  width: 75%;
}
.bottom-wrapper-stages {
  /* position: sticky; */
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 1000;
  padding: 12px 80px;
  background-color: #fcfcfc;
  box-shadow: 5px 0 8px 0 hsla(0, 0%, 65%, 0.5);
  display: flex;
  justify-content: space-between;
}
.bottom-wrapper-stages button.cancel {
  outline: none;
  border: none;
  background: none;
  font-family: "Nunito Sans", sans-serif;
  border: 1px solid #e0201c;
  padding: 8px 45px;
  border-radius: 4px;
  /* min-width: 11.75rem; */
  color: #e0201c;
  font-size: 16px;
  cursor: pointer;
}
.bottom-wrapper-stages .btn-next button.next {
  display: none;
  display: block;
  outline: none;
  border: none;
  background-color: #00af63;
  font-family: "Nunito Sans", sans-serif;
  padding: 8px 45px;
  border-radius: 4px;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  min-width: 180px;
  position: relative;
  height: 50px;
}
.bottom-wrapper-stages .btn-next {
  position: relative;
}
.bottom-wrapper-stages .btn-next svg {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  right: 30px;
  color: #fff;
  font-size: 30px;
}
.bottom-wrapper-stages button.next {
  display: flex;
  justify-content: space-between;
  gap: 25px;
}
.stages-con {
  display: flex;
  gap: 25px;
  justify-content: space-between;
  align-items: center;
}
.stages-con .select-qr {
  display: flex;
  align-items: center;
  gap: 30px;
}
.stages-con .select-qr .wrap {
  display: flex;
  gap: 10px;
}
.stages-con .select-qr .wrap h5 {
  margin: 0;
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  color: #8c8c8c;
  background-color: #f3eded;
  padding: 10px;
  height: 24px;
  width: 24px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #8c8c8c;
}
.stages-con .select-qr .wrap h5.active {
  background-color: #e0201c;
  color: #fff;
  border: 1px solid #e0201c;
}
.stages-con .select-qr .wrap p {
  margin: 0;
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
}
.stages-con .select-qr .wrap p.active {
  color: #e0201c;
}
.stages-con .select-qr svg {
  font-size: 20px;
}

/*********************************** WHO WE ARE CSS *********************************************/
.who-we-are {
}
.who-we-are .heading {
  padding: 4rem 1rem;
  text-align: center;
}
.who-we-are .heading h1 {
  font-size: 40px;
  font-family: "poppins", sans-serif;
  color: #000000;
  font-weight: 800;
}
.who-we-are .content {
  background-color: #f6fafe;
  padding: 40px;
}
.who-we-are .content .wrap {
  background-color: #fcfcfc;
  padding: 1.5rem;
  border-radius: 0.5rem;
  max-width: 940px;
  margin-inline: auto;
}
.who-we-are .content .wrap p {
  text-align: start;
  color: #000000;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5rem;
}
.who-we-are .content .wrap .bottom-con {
  margin-top: 32px;
}
.who-we-are .content .wrap .bottom-con h5 {
  color: #000000;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 600;
}
.who-we-are .content .wrap .bottom-con ul {
}
.who-we-are .content .wrap .bottom-con ul li {
  color: #000000;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  margin-bottom: 18px;
}
#terms .heading p {
  font-size: 16px;
  color: #000000;
  font-family: "Nunito Sans", sans-serif;
}
#terms .content .wrap h2 {
  font-size: 24px;
  font-family: "poppins", sans-serif;
  color: #000000;
  font-weight: 800;
  margin-bottom: 20px;
}
.who-we-are .content .wrap .one,
.who-we-are .content .wrap .two,
.who-we-are .content .wrap .three,
.who-we-are .content .wrap .four,
.who-we-are .content .wrap .five,
.who-we-are .content .wrap .six,
.who-we-are .content .wrap .seven,
.who-we-are .content .wrap .eight,
.who-we-are .content .wrap .nine,
.who-we-are .content .wrap .ten,
.who-we-are .content .wrap .eleven {
  margin-bottom: 50px;
}
.who-we-are .content .wrap .nested-list {
  margin-left: 10px;
}
.who-we-are .content .wrap ul li {
  font-size: 16px;
  color: #000000;
  font-family: "Nunito Sans", sans-serif;
}
.who-we-are .content .wrap ul li span {
  color: #e0201c;
  cursor: pointer;
}
.who-we-are .content .wrap h5 {
  margin-bottom: 16px;
  font-size: 18px;
  color: #000000;
  font-family: "Nunito Sans", sans-serif;
}
.who-we-are .content .wrap p span {
  color: #e0201c;
  cursor: pointer;
}
.table-wrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin: 0 -16px;
}
.who-we-are .table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1.5rem;
  min-width: 600px;
  /* table-layout: fixed; */
}
.who-we-are .table thead tr {
  background-color: #f6fafe;
}
.who-we-are .table th {
  width: 100px;
  overflow: hidden;
  padding: 0.75rem 1rem;
  font-size: 18px;
  font-family: "Nunito Sans", sans-serif;
  color: #000000;
  font-weight: 700;
  text-align: center;
  border: none;
}

.who-we-are .table td {
  width: 100px;
  overflow: hidden;
  padding: 0.75rem 1rem;
  word-break: break-word;
  word-wrap: break-word;
  white-space: normal;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
  color: #000000;
}
.who-we-are .table tbody tr {
  border-bottom: 1px solid #bfbfbf;
}

/*********************************** PRICING CSS *********************************************/
.pricing {
}
.pricing .content {
  padding: 40px 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pricing .content p {
  font-size: 24px;
  color: #000000;
  font-family: "Nunito Sans", sans-serif;
  margin: 0;
  font-weight: 500;
}
.pricing .content button {
  border: none;
  outline: none;
  background: #e0201c;
  padding: 0.7rem 1rem;
  font-family: "Nunito Sans", sans-serif;
  font-size: 20px;
  color: #fff;
  border-radius: 4px;
  font-weight: 600;
  min-width: 280px;
}
.pricing .content button.disabled-btn {
  background-color: #ccc;
  cursor: not-allowed;
  color: #fff;
  background-color: #e56462;
}
.pricing .card-container {
  padding: 88px 80px 30px 80px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 18px;
  background-color: #f6fafe;
}
.pricing .card-container .cardd {
  background-color: rgb(252, 252, 252);
  box-shadow: rgba(89, 89, 89, 0.18) 0px 5px 8px 0px;
  border-radius: 8px;
  padding: 20px;
  flex: 1;
  cursor: pointer;
}
.pricing .card-container .cardd.active {
  border: 1px solid #e0201c;
  border-radius: 0px 0px 8px 8px;
}
.pricing .card-container .cardd .top {
  display: flex;
  gap: 20px;
}
.pricing .card-container .cardd .top span {
  width: 18px;
  height: 18px;
  background-color: transparent;
  border-radius: 18px;
  border: 1px solid #bfbfbf;
}
.pricing .card-container .cardd.active .top span {
  border: 5px solid #e0201c;
}
.pricing .card-container .cardd .top h1 {
  font-size: 24px;
  font-family: "Nunito Sans", sans-serif;
  color: #000000;
}
.pricing .card-container .cardd .pricing {
  font-size: 24px;
  font-family: "Nunito Sans", sans-serif;
  color: #000000;
  font-weight: 800;
  text-align: center;
}

.pricing .card-container .cardd ul {
  list-style-type: none;
  padding: 0;
}
.pricing .card-container .cardd ul li {
  font-size: 18px;
  font-family: "Nunito Sans", sans-serif;
  color: #737373;
  font-weight: 500;
  padding-bottom: 6px;
  display: flex;
  align-items: start;
  gap: 16px;
}
.pricing .card-container .cardd ul li svg {
  /* margin-right: 20px; */
  color: #737373;
  font-weight: 200;
  opacity: 70%;
  flex-shrink: 0;
}
.pricing .card-container .cardd ul li {
  flex-grow: 1;
}
.pricing .bottom {
  background-color: #f6fafe;
  padding: 0px 0px 20px 0px;
}
.pricing .bottom p {
  text-align: center;
  max-width: 297px;
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
  color: #000000;
  font-weight: 500;
  margin: 0;
  margin-inline: auto;
}
.pricing .bottom p span {
  font-weight: 800;
}
.pricing .card-container .cardd:nth-child(2) {
  position: relative;
}
.pricing .card-container .cardd .most-popular {
  position: absolute;
  top: -46px;
  width: 101%;
  left: 0;
  height: 46px;
  margin-left: -2px;
}

.pricing .card-container .cardd .most-popular h3 {
  text-align: center;
  font-family: "poppins", sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  padding: 12px;
  background-color: #e56462;
  color: #fff;
  border-radius: 10px 10px 0px 0px;
  height: 46px;
}

/*********************************** USERDASHBOARD CSS *****************************************/

.userDashboard {
  display: flex;
  min-height: 100vh;
  overflow: hidden;
}

.sidebar {
  width: 278px;
  background-color: #051a2e;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Custom scrollbar styles */
.sidebar::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #307fe2; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Round the scrollbar thumb */
}

.sidebar::-webkit-scrollbar-track {
  background-color: #051a2e; /* Background color of the scrollbar track */
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background-color: #0a335c; /* Change color when hovering over the scrollbar thumb */
}

.userDashboard .content-page {
  margin-left: 278px;
  flex: 1;
  padding: 0px 20px 20px 20px;
  overflow-y: auto;
  /* width: calc(100% - 250px); */
}
.sidebar .logo {
  padding: 30px;
  margin-bottom: 50px;
  cursor: pointer;
}

/*********************************** CONTACT PAGE CSS **************************************/
.contact {
}
.contact .top {
  padding: 4rem 1rem;
  text-align: center;
}
.contact .top .content {
  max-width: 530px;
  margin-inline: auto;
}
.contact .top .content h1 {
  color: #000000;
  font-size: 40px;
  font-family: "poppins", sans-serif;
  font-weight: 800;
  margin-bottom: 12px;
}
.contact .top .content p {
  font-size: 16px;
  color: #000000;
  font-weight: 500;
  font-family: "Nunito Sans", sans-serif;
}
.contact .bottom {
  background-color: #f6fafe;
  padding: 40px;
}
.contact .bottom .form {
  background-color: #fcfcfc;
  padding: 1.5rem;
  border-radius: 0.5rem;
  max-width: 710px;
  margin-inline: auto;
  box-shadow: 2px 0 6px 0 hsla(0, 0%, 65%, 0.25);
}
.contact .bottom .form .input-wrap {
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
  margin-bottom: 16px;
}
.contact .bottom .form .input-wrap label {
  font-size: 16px;
  color: #000000;
  font-weight: 500;
  font-family: "Nunito Sans", sans-serif;
}
.contact .bottom .form .input-wrap input,
.contact .bottom .form .input-wrap select {
  outline: none;
  width: 100%;

  display: block;
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #bfbfbf;
  border-radius: 0.25rem;
  font-size: 14px;
  transition: border 0.2s ease-in-out;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.contact .bottom .form .input-wrap input::placeholder {
  color: #7c7c7c;
}
.contact .bottom .form .input-wrap select {
  color: #7c7c7c;
}

.contact .bottom .form .input-wrap select option {
}
.input-wrap.forgot {
  border-bottom: 1px solid #bfbfbf;
  padding-bottom: 16px;
}
.contact .bottom .form .btn-wrapper {
  text-align: center;
  margin-bottom: 16px;
}
.contact .bottom .form .btn-wrapper button {
  border: none;
  outline: none;
  background: #e0201c;
  padding: 0.7rem 1rem;
  font-family: "Nunito Sans", sans-serif;
  font-size: 20px;
  color: #fff;
  border-radius: 4px;
  font-weight: 600;
  width: 200px;
}
.contact .bottom .form p.policy {
  text-align: center;
  font-size: 14px;
  color: #000000;
  font-family: "Nunito Sans", sans-serif;
  max-width: 325px;
  margin-inline: auto;
}
.contact .bottom .form p.policy a {
  color: #e0201c;
  text-decoration: none;
}

/*********************************** FAQ PAGE CSS *****************************************/
.faq {
}
.faq .top {
  padding: 4rem 1rem;
  text-align: center;
}
.faq .top h1 {
  color: #000000;
  font-size: 40px;
  font-family: "poppins", sans-serif;
  font-weight: 800;
}
.faq .bottom {
  background-color: #f6fafe;
  padding: 40px;
}

/*********************************** QrAnalytics PAGE CSS *****************************************/
.qrAnalytics {
}
.qrAnalytics .top {
  padding: 2.5rem 1rem 1rem;
}
.qrAnalytics .top h1 {
  font-size: 24px;
  color: #000000;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
}
.qrAnalytics .bottom {
  background-color: #f6fafe;
  padding: 1.5rem;
}

.qrAnalytics .bottom .period-con {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
  margin-bottom: 32px;
}
.qrAnalytics .bottom .period-con p {
  color: #000000;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  margin: 0;
}
.qrAnalytics
  .bottom
  .period-con
  .css-hjf7j2-MuiInputBase-root-MuiOutlinedInput-root {
  background-color: #fff;
  outline: none;
  /* border: 1px solid #bfbfbf; */
  min-width: 320px;
  height: 50px;
}
.qrAnalytics
  .bottom
  .period-con
  .css-10o2lyd-MuiStack-root
  > .MuiTextField-root {
}
.qrAnalytics
  .bottom
  .period-con
  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0.85rem;
}
.qrAnalytics
  .bottom
  .period-con
  .css-1xfd8nx-MuiFormLabel-root-MuiInputLabel-root {
  color: #8c8c8c;
  font-weight: 600;
  font-size: 18px;
}
.qrAnalytics .bottom .period-con .css-10o2lyd-MuiStack-root {
  /* padding: 0; */
}
.qrAnalytics .bottom .period-con .export-data {
  border: none;
  outline: none;
  background: #e16a68;
  padding: 0.7rem 1rem;
  font-family: "Nunito Sans", sans-serif;
  font-size: 20px;
  color: #fff;
  border-radius: 4px;
  font-weight: 600;
  align-self: flex-end;
  height: 50px;
  min-width: 188px;
}

.qrAnalytics .bottom .analytics-con {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  margin-bottom: 32px;
}
.qrAnalytics .bottom .analytics-con .cardd {
  box-shadow: 0 5px 8px 0 rgba(89, 89, 89, 0.18);
  border-radius: 0.5rem;
  background-color: #fcfcfc;
  padding: 1.5rem;
  flex: 1;
}
.qrAnalytics .bottom .analytics-con .cardd .wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 10px;
}
.qrAnalytics .bottom .analytics-con .cardd .wrap img {
}
.qrAnalytics .bottom .analytics-con .cardd .wrap p {
  margin: 0;
  color: #8c8c8c;
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
}
.qrAnalytics .bottom .analytics-con .cardd h3 {
  font-size: 28px;
  color: #212529;
  margin: 0;
  margin-left: 40px;
  font-weight: bold;
}
.qrAnalytics .bottom .all-card-con {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
}
.qrAnalytics .bottom .all-card-con .cardd {
  box-shadow: 0 5px 8px 0 rgba(89, 89, 89, 0.18);
  border-radius: 0.5rem;
  background-color: #fcfcfc;
  padding: 1.5rem;
  height: 350px;
  width: 100%;
}
.qrAnalytics .bottom .all-card-con .cardd:last-child {
  grid-column: span 2;
  width: 100%;
}
.qrAnalytics .bottom .all-card-con .cardd p {
  font-size: 18px;
  color: #000000;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
}
.qrAnalytics .bottom .all-card-con .cardd h4.stats-txt {
  font-family: "Nunito Sans", sans-serif;
  color: #403f3f;
  font-size: 16px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.qrAnalytics .bottom .graph-con {
  box-shadow: 0 5px 8px 0 rgba(89, 89, 89, 0.18);
  border-radius: 0.5rem;
  background-color: #fcfcfc;
  padding: 1.5rem;
  margin-bottom: 32px;
  position: relative;
}
.qrAnalytics .bottom .graph-con .stats-txt {
  font-family: "Nunito Sans", sans-serif;
  color: #403f3f;
  font-size: 16px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}
.qrAnalytics .bottom .graph-con p {
  font-size: 18px;
  color: #000000;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
}
.download-csv-btn {
  display: inline-block;
  padding: 12px 25px;
  background-color: transparent;
  color: #e0201c;
  border: 2px solid #e0201c;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 12px rgba(224, 32, 28, 0.3);
  margin-bottom: 25px;
  font-family: poppins, sans-serif;
  text-transform: uppercase;
}

.download-csv-btn:hover {
  background-color: #e0201c;
  color: #fff;
  box-shadow: 0px 6px 15px rgba(224, 32, 28, 0.5);
  transform: translateY(-2px);
}

.download-csv-btn:active {
  transform: translateY(0); /* Remove lift effect when clicked */
  box-shadow: 0px 2px 6px rgba(224, 32, 28, 0.5); /* Subtle shadow when clicked */
}

/*********************************** QRMAIN PAGE CSS *****************************************/
.qr-main-page {
}
.qr-main-page .top {
  padding: 2.5rem 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.qr-main-page .top h1 {
  font-size: 24px;
  color: #000000;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  margin: 0;
}
.qr-main-page .top .btn-wrapper {
}
.qr-main-page .top .btn-wrapper a {
  text-decoration: none;
}
.qr-main-page .top .btn-wrapper button {
  border: none;
  outline: none;
  background: #e0201c;
  padding: 0.7rem 1rem;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  color: #fff;
  border-radius: 4px;
  font-weight: 600;
  height: 50px;
  display: flex;
  justify-content: center;
  gap: 6px;
  align-items: center;
  /* min-width: 188px; */
}

.qr-main-page .bottom {
  background-color: #f6fafe;
  padding: 12px;
  border-radius: 6px;
}
.qr-main-page .bottom .all-qrCode-con {
}
.qr-main-page .bottom .all-qrCode-con .result-cardd {
  background-color: #fcfcfc;
  padding: 1rem;
  display: block;
  margin-bottom: 1.25rem;
  position: relative;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  /* align-items: flex-start; */
  gap: 12px;
  box-shadow: 0 5px 8px 0 rgba(89, 89, 89, 0.18);
}
.qr-main-page .bottom .all-qrCode-con .result-cardd .one {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
  min-width: 250px;
}
.qr-main-page .bottom .all-qrCode-con .result-cardd .one .img-con {
  width: 80px;
  height: 80px;
  object-fit: contain;
  border: 1px solid #e0201c;
  border-radius: 4px;
  cursor: pointer;
}
.qr-main-page
  .bottom
  .all-qrCode-con
  .result-cardd
  .one
  .img-con
  .notselectSvg {
  width: 80px;
  height: 80px;
  object-fit: contain;
  padding: 3px;
}
.qr-main-page .bottom .all-qrCode-con .result-cardd .one .img-con svg {
  /* width: 100%;
  height: 100%; */
  width: 80px;
  height: 80px;
  object-fit: contain;
  padding: 5px;
}
.qr-main-page .bottom .all-qrCode-con .result-cardd .one .img-con svg canvas {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.qr-main-page .bottom .all-qrCode-con .result-cardd .one .img-con canvas {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 2px;
}
.qr-main-page .bottom .all-qrCode-con .result-cardd .one .img-con img {
  width: 100%;
  height: 100%;
  padding: 5px;
}
.qr-main-page .bottom .all-qrCode-con .result-cardd .one .content-wrap {
}
.qr-main-page .bottom .all-qrCode-con .result-cardd .one .content-wrap h4 {
  color: #e0201c;
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
  margin: 0;
  font-weight: 600;
  text-transform: capitalize;
}
.qr-main-page .bottom .all-qrCode-con .result-cardd .one .content-wrap h3 {
  color: #404040;
  font-size: 14px;
  font-family: "Nunito Sans", sans-serif;
  margin: 0;
  font-weight: 700;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
.qr-main-page .bottom .all-qrCode-con .result-cardd .two {
  display: flex;
  align-items: center;
  gap: 24px;
  flex: 1;
}
.qr-main-page .bottom .all-qrCode-con .result-cardd .two p.status {
  border: 1px solid #737373;
  color: #737373;
  background-color: #f2f2f2;
  padding: 4px 8px;
  min-width: 50px;
  font-size: 10px;
  border-radius: 16px;
  white-space: nowrap;
  text-align: center;
  margin: 0;
}
.qr-main-page .bottom .all-qrCode-con .result-cardd .two .modifiedDate-con {
}
.qr-main-page
  .bottom
  .all-qrCode-con
  .result-cardd
  .two
  .modifiedDate-con
  .wrap {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 2px;
}
.qr-main-page
  .bottom
  .all-qrCode-con
  .result-cardd
  .two
  .modifiedDate-con
  .wrap
  h6 {
  color: #8c8c8c;
  font-size: 12px;
  font-family: "Nunito Sans", sans-serif;
  margin: 0;
}
.qr-main-page
  .bottom
  .all-qrCode-con
  .result-cardd
  .two
  .modifiedDate-con
  .wrap
  h6.link {
  /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px; */
}
.qr-main-page .bottom .all-qrCode-con .result-cardd .three {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  flex: 1;
}
.qr-main-page .bottom .all-qrCode-con .result-cardd .three p {
  color: #8c8c8c;
  margin: 0;
  font-size: 12px;
  font-family: "Nunito Sans", sans-serif;
}
.qr-main-page .bottom .all-qrCode-con .result-cardd .three h1 {
  color: #000000;
  font-family: poppin, sans-serif;
  font-size: 18px;
  margin: 0;
  font-weight: 800;
}
.qr-main-page .bottom .all-qrCode-con .result-cardd .four {
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: end;
  flex: 2;
}
.qr-main-page .bottom .all-qrCode-con .result-cardd .four p {
  margin: 0;
  padding: 4px 4px;
  border-radius: 4px;
  border: 1px solid #e0201c;
  color: #e0201c;
  font-size: 12px;
  font-family: "Nunito Sans", sans-serif;
  cursor: pointer;
}
.qr-main-page .bottom .all-qrCode-con .result-cardd .four p:nth-child(2) {
  display: flex;
  align-items: center;
  gap: 5px;
  align-self: flex-end;
}
.qr-main-page .bottom .all-qrCode-con .result-cardd .four p:nth-child(3) {
  display: flex;
  align-items: center;
  gap: 5px;
  align-self: flex-end;
}
.qr-main-page .bottom .all-qrCode-con .result-cardd .four .delete-box {
  position: relative;
}
.qr-main-page .bottom .all-qrCode-con .result-cardd .four .delete-box .box {
  gap: 4px;
  position: absolute;
  right: 0;
  top: 40px;
  padding: 18px;
  box-sizing: border-box;
  background-color: #fcfcfc;
  box-shadow: 2px 0 6px 0 hsla(0, 0%, 65%, 0.25);
  border-radius: 8px;
  transition: bottom 0.2s ease-in-out;
  height: auto;
  z-index: 2;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.qr-main-page
  .bottom
  .all-qrCode-con
  .result-cardd
  .four
  .delete-box
  .box
  .delete-wrap {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}
.qr-main-page .bottom .all-qrCode-con .result-cardd .four .delete-box .box h4 {
  font-size: 12px;
  color: #e0201c;
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
}
.qr-main-page .bottom .all-qrCode-con .result-cardd .four .delete-box .box svg {
}

.qr-main-page .qrFilterCon {
  background-color: #f6fafe;
  padding: 12px;
}
.qr-main-page .qrFilterCon .containerr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.qr-main-page .qrFilterCon .containerr .left {
  display: flex;
  align-items: center;
  gap: 9px;
}
.qr-main-page .qrFilterCon .containerr .statusDropdown {
  width: 220px;
  position: relative;
}
.qr-main-page .qrFilterCon .containerr .qrTypeDropdown {
  width: 220px;
  position: relative;
}
.qr-main-page .qrFilterCon .cleanFilter {
  color: #e0201c;
  font-size: 16px;
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  cursor: pointer;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 2px;

  &:hover {
    color: #f35b58;
  }
}
.qr-main-page .qrFilterCon .cleanFilter h6 {
  margin: 0;
}
.qr-main-page .qrFilterCon .containerr .sortByDropdown {
  width: 220px;
  position: relative;
}
.qr-main-page .qrFilterCon .containerr .Select__prefix {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  padding-left: 0.5rem;
  color: #bfbfbf;
  z-index: 1;
}
.qr-main-page .qrFilterCon .containerr select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  padding: 0.5rem 2rem 0.5rem 0.75rem;
  background-color: #fcfcfc;
  border-radius: 0.25em;
  border: 1px solid #bfbfbf;
  font-size: 14px;
  outline: 0;
  height: 2.5rem;
  width: 100%;
  background-color: #fff !important;
  padding-inline-start: 80px;
  &:hover {
    border: 1px solid #8c8c8c;
  }
}
.qr-main-page .qrFilterCon .containerr select option {
  color: #262626;
  padding: 0.75rem 2rem 0.75rem 0.75rem;
}

.qr-main-page .qrFilterCon .containerr .dropdown-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
.qr-main-page .qrFilterCon .containerr select:focus {
  border: 1px solid #e0201c;
}

.qr-main-page .qrFilterCon .qrTypeDropdown .qrTypeDropdown {
  position: relative;
  width: 220px;
}

.qr-main-page .qrFilterCon .qrTypeDropdown .dropdown-selected {
  padding: 0.5rem;
  border: 1px solid #bfbfbf;
  border-radius: 0.25em;
  cursor: pointer;
  background-color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 40px;
  color: #e0201c;
  font-family: "Poppins", sans-serif;
  padding-inline-start: 66px;
}
.qr-main-page .qrFilterCon .qrTypeDropdown .dropdown-selected p {
  margin: 0;
  color: #e0201c;
  font-family: "Poppins", sans-serif;
  max-width: 80px;
  text-overflow: ellipsis;
}
.qr-main-page .qrFilterCon .qrTypeDropdown .dropdown-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.qr-main-page .qrFilterCon .qrTypeDropdown .dropdown-options {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #bfbfbf;
  border-radius: 0.25em;
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
}

.qr-main-page .qrFilterCon .qrTypeDropdown:hover .dropdown-options {
  display: block;
}

.qr-main-page .qrFilterCon .dropdown-options label {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0.5rem;
  cursor: pointer;
}

.qr-main-page .qrFilterCon .containerr .right {
}
/*********************************** QRCREATORDETAIL CSS *********************************/
.qr-editor-detail {
  padding-inline: 100px;
  /* height: 100vh; */
  padding-top: 3rem;
}
.qr-editor-detail .top {
}
.qr-editor-detail .top h1 {
  color: #262626;
  font-family: "Nunito Sans", sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}
.qr-editor-detail .url {
  margin-bottom: 100px;
}
.qr-editor-detail .url .containerr {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.qr-editor-detail .url .containerr .left {
  flex: 2;
  background-color: #f6fafe;
  padding: 40px;
  border-radius: 10px;
}
.qr-editor-detail .url .containerr .right {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.layout-content.url img {
  width: 100%;
  object-fit: contain;
  height: 550px;
  position: sticky;
  top: 0px;
}

.qr-editor-detail .url .containerr .left .accordion-item {
  box-shadow: 0 5px 8px 0 rgba(89, 89, 89, 0.18);
  background: #fcfcfc;
  border-radius: 0.5rem;
  margin-bottom: 12px;
  padding: 12px 12px;
}
.qr-editor-detail .url .containerr .left .accordion-button {
  background-color: transparent;
  box-shadow: none;
  color: #262626;
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
}
.qr-editor-detail .url .containerr .left .accordion-body {
  /* padding: 5px 1.25rem; */
}
.qr-editor-detail .url .containerr .left .accordion-body input {
  outline: none;
  display: block;
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #bfbfbf;
  border-radius: 0.25rem;
  font-size: 14px;
  transition: border 0.2s ease-in-out;
}

/*********************************** My-Account PAGE CSS ************************************/
.my-account {
}
.my-account .top {
  padding: 2.5rem 1rem 1rem;
  margin-bottom: 32px;
}
.my-account .top h1 {
  font-size: 24px;
  color: #000000;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
}
.my-account .top p {
  font-size: 12px;
  color: #000000;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
}
.my-account .top p span {
  color: #8c8c8c;
}
.my-account .bottom {
}
.my-account .bottom .card-con {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.my-account .bottom .card-con .cardd {
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 2px 0 6px 0 hsla(0, 0%, 65%, 0.25);
  background-color: #fcfcfc;
  flex: 1 1 calc(50% - 20px);
  height: 224px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.my-account .bottom .card-con .cardd:nth-child(3) {
  margin-bottom: 0;
}
.my-account .bottom .card-con .cardd:nth-child(4) {
  margin-bottom: 0;
}
.my-account .bottom .card-con .cardd .heading {
  margin-bottom: 24px;
}
.my-account .bottom .card-con .cardd .heading h1 {
  color: #000;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;
}
.my-account .bottom .card-con .cardd .user-detail {
  margin-bottom: 24px;
}
.my-account .bottom .card-con .cardd .user-detail .wrap {
  display: flex;
  align-items: center;
  gap: 32px;
}
.my-account .bottom .card-con .cardd .user-detail p {
  color: #000;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.my-account .bottom .card-con .cardd .user-detail p span {
  color: #8c8c8c;
  margin-left: 8px;
}
.my-account .bottom .card-con .cardd .edit-info {
  text-align: right;
}
.my-account .bottom .card-con .cardd .edit-info p {
  color: #e0201c;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.my-account .bottom .card-con .cardd .user-detail p.user-data-email {
  font-weight: 600;
}
.my-account .bottom .card-con .cardd .edit-account-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}
.my-account .bottom .card-con .cardd .edit-account-status p {
  border: 1px solid #e0201c;
  padding: 4px 12px;
  background-color: #ffe5e5;
  font-size: 12px;
  border-radius: 18px;
  font-family: "Nunito Sans", sans-serif;
  color: #e0201c;
  margin: 0;
}
.my-account .bottom .card-con .cardd .edit-account-status button {
  border: none;
  outline: none;
  background: #e0201c;
  padding: 0.5rem 0.5rem;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  color: #fff;
  border-radius: 4px;
  font-weight: 600;
}

/*********************************** My-BILLING PAGE CSS ************************************/
.my-billing {
}
.my-billing .top {
  padding: 2.5rem 1rem 1rem;
  margin-bottom: 32px;
}
.my-billing .top h1 {
  font-size: 24px;
  color: #000000;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
}
.my-billing .bottom {
  background-color: #ffffff;
  box-shadow: 0 5px 8px 0 rgba(89, 89, 89, 0.18);
  height: 455px;
  border-radius: 0.5rem;
  background-color: #fcfcfc;
}
.my-billing .bottom .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  height: 100%;
  max-width: 385px;
  margin-inline: auto;
}
.my-billing .bottom .content img {
  object-fit: contain;
}
.my-billing .bottom .content p {
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
  color: #000000;
  text-align: center;
}
.my-billing .bottom .content h1 {
  font-size: 24px;
  font-family: "Nunito Sans", sans-serif;
  color: #000000;
  font-weight: 800;
  text-align: center;
}

/*********************************** QR DESIGN PAGE CSS ************************************/
.qr-design {
  padding-inline: 100px;
  padding-top: 3rem;
}
.qr-design .top {
  margin-bottom: 20px;
}
.qr-design .top h1 {
  color: #262626;
  font-family: "Nunito Sans", sans-serif;
  font-size: 24px;
  font-weight: 700;
}
.qr-design .containerr {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 80px;
}
.qr-design .containerr .left {
  flex: 2;
}
.qr-design .containerr .right {
  /* flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 20px;
  height: 400px;
  margin-top: 100px; */

  align-items: center;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  gap: 20px;
  /* justify-content: center; */
  position: sticky;
  top: 20px;
  /* height: 400px; */
  margin-top: 100px;
}
.qr-design .bottom {
}
.qr-design .bottom .one {
  padding: 1.75rem 1.25rem;
  margin-bottom: 1rem;
  box-shadow: 0 5px 8px 0 rgba(89, 89, 89, 0.18);
  border-radius: 8px;
}
.qr-design .bottom .one h1 {
  color: #262626;
  font-weight: 700;
  text-align: left;
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
  margin-bottom: 16px;
}
.qr-design .bottom .one .card-con {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
}
.qr-design .bottom .one .card-con .cardd {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 1rem;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #bfbfbf;
  height: 108px;
  width: 100%;
}
.qr-design .bottom .one .card-con .cardd.active {
  border: 2px solid #e0201c;
}
.qr-design .bottom .one .card-con .cardd .wrap {
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px; */
}
.qr-design .bottom .one .card-con .cardd span {
  width: 20px;
  height: 18px;
  border-radius: 20px;
  border: 1px solid #bfbfbf;
}
.qr-design .bottom .one .card-con .cardd.active span {
  border: 5px solid #e0201c;
}
.qr-design .bottom .one .card-con .cardd .wrap h3 {
  color: #000000;
  text-align: left;
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  margin: 0;
}
.qr-design .bottom .one .card-con .cardd .wrap p {
  color: #000000;
  text-align: left;
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 500;
}
.qr-design .containerr .right {
}
.qr-design .containerr .right .img-con {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  align-items: center;
}
.qr-design .containerr .right .qr-preview {
}
.qr-preview svg {
  /* position: absolute; */
  /* top: 50%; */
  /* left: 50%;
  transform: translate(-50%, -50%); */
  /* margin: 40px 0px; */
  width: 100%;
  height: 300px;
}
.qr-preview .notselectSvg {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  margin: 40px 0px;
  text-align: center;
  /* width: 100%; */
  height: 300px;

  margin: 0;
  height: 250px;
  border: 4px solid #e0201c;
  padding: 14px;
  border-radius: 6px;
}
.qr-preview.design .notselectSvg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.qr-preview.design svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}
.qr-preview svg canvas {
  width: 100%;
  height: 100%;
  padding: 2px;
}
.qr-design .containerr .right .img-con img.mobile-frame {
  width: 80%;
  object-fit: contain;
  position: relative;
}
.qr-design .containerr .right .img-con .canvas-img {
  position: absolute;
  /* width: 215px; */
  z-index: 100;
}
.qr-design .two {
  margin-bottom: 1rem;
}
.qr-design .two .accordion .accordion-item {
  border: none;
}
.qr-design .two .accordion {
  background-color: #fcfcfc;
  border-radius: 8px;
  box-shadow: 0 5px 8px 0 rgba(89, 89, 89, 0.18);
}
.qr-design .two .accordion-header .accordion-button {
  background-color: #fff;
  box-shadow: none;
  color: #262626;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  border: none;
}

.qr-design .two .qr-frames-con {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 16px;
}
.qr-design .two .qr-frames-con .img-con {
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #bfbfbf;
  cursor: pointer;
  flex-basis: calc(100% / 6 - 12px);
  box-sizing: border-box;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.qr-design .two .qr-frames-con .img-con svg {
  display: block;
  max-width: 100%;
  height: auto;
}
.qr-design .two .qr-frames-con .img-con.active {
  border: 2px solid #e0201c;
}
.qr-design .two .bottom {
  background-color: #f6fafe;
  padding: 1rem;
  border-radius: 8px;
}
.qr-design .two .bottom .top-con {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}

.qr-design .two .bottom .color-picker-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.qr-design .two .bottom .color-box {
  width: 38px;
  height: 34px;
  cursor: pointer;
  border-radius: 4px;
  align-self: end;
  margin-bottom: 8px;
}

.qr-design .two .bottom .color-picker-popup {
  position: absolute;
  bottom: 70px;
  right: 0;
  z-index: 10;
  /* background: white;
  padding: 10px; */
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.qr-design .two .bottom .color-picker-wrapper .wrap {
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 100%;
}
.qr-design .two .bottom .color-picker-wrapper .wrap label {
  color: #262626;
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 500;
}
.qr-design .two .bottom .color-picker-wrapper .wrap input {
  display: block;
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #bfbfbf;
  border-radius: 0.25rem;
  font-size: 14px;
  transition: border 0.2s ease-in-out;
}

.qr-design .three {
  margin-bottom: 16px;
  /* margin-bottom: 100px; */
}
.qr-design .three .accordion {
  background-color: #fcfcfc;
  border-radius: 8px;
  box-shadow: 0 5px 8px 0 rgba(89, 89, 89, 0.18);
}
.qr-design .three .accordion-item {
  border: none;
}
.qr-design .three .accordion-button {
  background-color: #fff;
  box-shadow: none;
  color: #262626;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  border: none;
}
.qr-design .three .choose-contrasting-color {
  background-color: #f2f2f2;
  padding: 1rem 2rem;
  border-radius: 12px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 6px;
}
.qr-design .three .choose-contrasting-color svg {
  width: 24px;
  height: 24px;
}
.qr-design .three .choose-contrasting-color p {
  margin: 0;
  color: #262626;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  max-width: 600px;
  font-weight: 600;
}
.qr-design .three .dotStylePicker {
  margin-bottom: 16px;
}
.qr-design .three .dotStylePicker ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  gap: 12px;
}
.qr-design .three .dotStylePicker ul li {
  width: 2.5rem;
  height: 2.5rem;
  background-color: #fcfcfc;
  list-style: none;
  margin-bottom: 0;
  border-radius: 0.7rem;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qr-design .three .dotStylePicker ul li svg {
  width: 24px;
}
.qr-design .three .dotStylePicker ul li.active {
  border: 1px solid #e0201c;
}
.qr-design .three .dotStylePicker ul li.active-corner {
  border: 1px solid #e0201c;
}
.qr-design .three .dotStylePicker h3 {
  color: #262626;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin-top: 40px;
}
.qr-design .three .dot-bg-color-con {
  background-color: #f6fafe;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 16px;
}
.color-picker-con {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}
.color-picker-con.bg-custom-color {
  background-color: #f2f2f2;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-top: 24px;
}
.color-picker-con .color-picker-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
}
.color-picker-con .color-picker-wrapper .wrap {
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 100%;
}
.color-picker-con .color-picker-wrapper .wrap label {
  color: #262626;
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 500;
}
.color-picker-con .color-picker-wrapper .wrap input {
  display: block;
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #bfbfbf;
  border-radius: 0.25rem;
  font-size: 14px;
  transition: border 0.2s ease-in-out;
  outline: none;
}
.color-picker-con .color-picker-wrapper .color-box {
  width: 38px;
  height: 34px;
  cursor: pointer;
  border-radius: 4px;
  align-self: end;
  margin-bottom: 8px;
  border: 1px solid #d9d9d9;
}
.color-picker-con .color-picker-popup {
  position: absolute;
  bottom: 70px;
  right: 0;
  z-index: 10;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.color-picker-con .input-text-con {
  width: 100%;
}
.color-picker-con .input-text-con input {
  display: block;
  outline: none;
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #bfbfbf;
  border-radius: 0.25rem;
  font-size: 14px;
  transition: border 0.2s ease-in-out;
}
.qr-design .four {
  margin-bottom: 100px;
  display: none;
}
.qr-design .four .accordion {
  background-color: #fcfcfc;
  border-radius: 8px;
  box-shadow: 0 5px 8px 0 rgba(89, 89, 89, 0.18);
}
.qr-design .four .accordion-item {
  border: none;
}
.qr-design .four .accordion-button {
  background-color: #fff;
  box-shadow: none;
  color: #262626;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  border: none;
}
.qr-design .four .input-con {
}
.qr-design .four .input-con p {
  color: #262626;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
}

/* ************************ PDF ******************************** */
.pdf-page {
}
.pdf-page .containerr {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 100px;
}
.pdf-page .containerr .left {
  flex: 2;
  background-color: #f6fafe;
  padding: 40px;
  border-radius: 10px;
}
.pdf-page .containerr .right {
  flex: 1;
  display: flex;
  justify-content: center;
}
.pdf-page .containerr .right img {
  width: 100%;
  object-fit: contain;
  height: 550px;
  position: sticky;
  top: 0px;
}
.accord-con {
  margin-bottom: 16px;
}
.accord-con .accordion-item {
  box-shadow: 0 5px 8px 0 rgba(89, 89, 89, 0.18);
  background: #fcfcfc;
  border-radius: 0.5rem;
  margin-bottom: 12px;
  padding: 12px 12px;
}
.accord-con .accordion-button {
  background-color: transparent;
  box-shadow: none;
  color: #262626;
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
}
.accord-con-with-input .accordion-item {
  box-shadow: 0 5px 8px 0 rgba(89, 89, 89, 0.18);
  background: #fcfcfc;
  border-radius: 0.5rem;
  margin-bottom: 12px;
  padding: 12px 12px;
}
.accord-con-with-input {
  margin-bottom: 16px;
}
.accord-con-with-input .accordion-button {
  background-color: transparent;
  box-shadow: none;
  color: #262626;
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
}
.accord-con-with-input .accordion-body input {
  outline: none;
  display: block;
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #bfbfbf;
  border-radius: 0.25rem;
  font-size: 14px;
  transition: border 0.2s ease-in-out;
}
.pdf-page .pdf-uploader-container {
  text-align: center;
  /* margin-top: 20px; */
  border-style: dashed;
  padding-top: 16px;
  border-color: #ccc7c7;
}

.pdf-page .upload-button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.pdf-page .upload-button:hover {
  background-color: #45a049;
}

.pdf-page .file-size-text {
  margin-top: 10px;
  color: #888;
  font-size: 14px;
}

.pdf-page .file-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  /* margin-top: 20px; */
  margin: 10px 0px 20px 0px;
}

.pdf-page .delete-icon {
  color: #f44336;
  cursor: pointer;
  font-size: 18px;
}

.pdf-page .delete-icon:hover {
  color: #d32f2f;
}

.pdf-page .file-info p {
  margin: 0;
  font-size: 16px;
  word-break: break-all;
  padding-inline: 4px;
}

/* COLOR PICKER CSS */
.ThemePicker__wrapper {
  display: flex;
  gap: 1.5rem;
  width: 100%;
  margin-bottom: 16px;
}
.ThemePicker__labels {
  border: 1px solid transparent !important;
  padding: 1.25rem 0 0;
  flex-basis: content;
  display: flex;
  flex-wrap: wrap;
  margin-right: 0.5rem;
  flex-direction: column;
}
.ThemePicker__label--first {
  margin-bottom: 1.25rem;
}
.ThemePicker__item {
  border: 1px solid #8c8c8c;
  border-radius: 0.25rem;
  cursor: pointer;
  width: 15%;
  padding: 1rem 0.75rem;
}
.ThemePicker--active,
.u-active {
  outline: 2px solid #e0201c;
  border: 1px solid transparent !important;
}
.ThemePicker__swatch {
  border-radius: 0.25rem;
  display: inline-flex;
  width: 100%;
  height: 2rem;
  margin-bottom: 0.5rem;
}
.ThemePicker__item span {
  display: block;
}
.ThemePicker__add {
  background-color: #307fe2;
  color: #fcfcfc;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-weight: 700;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: background-color 0.2s ease-in-out;
  margin-top: 15px;
  font-size: 25px;
}
.input-wrap-comp {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  margin-bottom: 16px;
}
.input-wrap-comp label {
  color: #262626;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
}
.input-wrap-comp input {
  outline: none;
  display: block;
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #bfbfbf;
  border-radius: 0.25rem;
  font-size: 14px;
  transition: border 0.2s ease-in-out;
}
.select-wrap-comp {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  margin-bottom: 16px;
}
.select-wrap-comp .custom-select-container {
  position: relative;
  display: inline-block;
  width: 100%;
}
.select-wrap-comp label {
  color: #262626;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
}
.select-wrap-comp select {
  /* outline: none;
  display: block;
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #bfbfbf;
  border-radius: 0.25rem;
  font-size: 14px;
  transition: border 0.2s ease-in-out; */
  width: 100%;
  padding: 12px 30px 12px 10px;
  font-size: 16px;
  border-radius: 0.25rem;
  border: 1px solid #bfbfbf;
  background-color: #ffffff;
  appearance: none;
  font-size: 14px;
  color: #262626;
  cursor: pointer;
}
.select-wrap-comp select:focus {
  outline: none;
  border-color: #bfbfbf;
}
.select-wrap-comp .custom-select-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #aaa;
  pointer-events: none;
  font-size: 18px;
}
/* WIFI PAGE */
.wifi-page {
  margin-bottom: 100px;
}
.wifi-page .containerr {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.wifi-page .left {
  flex: 2;
  background-color: #f6fafe;
  padding: 40px;
  border-radius: 10px;
}
.wifi-page .right {
  flex: 1;
  display: flex;
  justify-content: center;
}
.wifi-page .right img {
  width: 100%;
  object-fit: contain;
  height: 550px;
  position: sticky;
  top: 0px;
}
.wrap-inp-cmp {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  gap: 15px;
  padding-bottom: 12px;
}
.input-checkbox-comp {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
}
.input-checkbox-comp label {
  color: #262626;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
}
.input-checkbox-comp input {
  outline: none;
  display: block;
  padding: 0.75rem;
  border: 1px solid #bfbfbf;
  border-radius: 0.25rem;
  font-size: 14px;
  transition: border 0.2s ease-in-out;
}

/* YOUTUBE PAGE */
.youtube-page {
  margin-bottom: 100px;
}
.youtube-page .containerr {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.youtube-page .left {
  flex: 2;
  background-color: #f6fafe;
  padding: 40px;
  border-radius: 10px;
}
.youtube-page .right {
  flex: 1;
  display: flex;
  justify-content: center;
}
.youtube-page .right img {
  width: 80%;
  object-fit: contain;
}
.btn-wrapper .btn-updateEmail {
  border: none;
  outline: none;
  background: #e0201c;
  padding: 0.7rem 1rem;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  color: #fff;
  border-radius: 4px;
  font-weight: 600;
  height: 50px;
  display: flex;
  justify-content: center;
  gap: 6px;
  align-items: center;
  width: 100%;
}

/* VCARD */
.vcard-page {
  margin-bottom: 100px;
}
.vcard-page .containerr {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.vcard-page .containerr .left {
  flex: 2;
}
.vcard-page .containerr .right {
  flex: 1;
  display: flex;
  justify-content: center;
}
.vcard-page .containerr .right img {
  width: 100%;
  object-fit: contain;
  height: 550px;
  position: sticky;
  top: 0px;
}

p.social-con-content {
  color: #262626;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.social-con-comp {
  /* display: flex;
  gap: 10px; */
  background-color: #f2f2f2;
  padding: 1rem;
  border-radius: 0.5rem;
  flex-wrap: wrap;
  justify-content: center;
}
.social-con-comp .wrapper {
  display: flex;
  padding-left: 0px;
  flex-wrap: wrap;
  list-style: none;
  /* height: 120px; */
  width: 100%;
  /* padding-top: 40px; */
  font-family: "Poppins", sans-serif;
  /* justify-content: center; */
  justify-content: space-around;

  /* gap: 8px; */
}

.social-con-comp .wrapper .icon {
  position: relative;
  background: #fff;
  border-radius: 50%;
  margin: 5px;
  width: 50px;
  height: 50px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.social-con-comp .wrapper .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background: #1da1f2;
  color: #fff;
  padding: 5px 8px;
  font-weight: 500;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.social-con-comp .wrapper .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #1da1f2;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.social-con-comp .wrapper .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.social-con-comp .wrapper .icon:hover span,
.social-con-comp .wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}
.social-con-comp .wrapper.app-social .icon {
  width: auto;
  height: auto;
  box-shadow: none;
}
.social-con-comp .wrapper.app-social .icon.active {
  border-radius: 10px;
  padding: 6px 0px;
}
.input-box-wrapper-social {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
  gap: 30px;
}
.input-box-wrapper-social .wrap {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
}
.input-box-wrapper-social .wrap input {
  outline: none;
  display: block;
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #bfbfbf;
  border-radius: 0.25rem;
  font-size: 14px;
}
.input-box-wrapper-social button {
  outline: none;
  border: 4px solid #bfbfbf;
  background: transparent;
  font-size: 40px;
  font-weight: bold;
  color: #bfbfbf;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  line-height: 0;
  padding: 0;
  padding-bottom: 8px;
}
.social-con-comp .wrapper .icon.active {
  border: 2px solid #1da1f2;
}

/* APP PAGE */
.app-page {
  margin-bottom: 100px;
}
.app-page .containerr {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.app-page .containerr .left {
  flex: 2;
  background-color: #f6fafe;
  padding: 40px;
  border-radius: 10px;
}
.app-page .containerr .right {
  flex: 1;
  display: flex;
  justify-content: center;
}
.app-page .right img {
  width: 100%;
  object-fit: contain;
  height: 550px;
  position: sticky;
  top: 0px;
}

/* IMAGE UPLOAD */
.img-upload-comp {
  position: relative;
  /* width: 200px;  */
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 40px;
}

.img-upload-comp p {
  margin-bottom: 10px;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
}

.img-upload-comp .img-wrapper {
  position: relative;
  border-radius: 0.5rem;
  border: 1px solid #bfbfbf;
  display: inline-block;
  padding: 0.5rem 0.4rem;
  position: relative;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  gap: 50px;

  margin-inline: auto;
}
.img-upload-comp .img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.img-upload-comp.uploaded-img {
  width: 100%;
  height: 85px;
  border-radius: 8px;
  object-fit: contain;
}

.img-upload-comp .icon-overlay {
  position: absolute;
  top: -20px;
  right: -20px;
  display: flex;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;
}
.img-upload-comp.icon-overlay label svg {
  color: #fff;
}
.img-upload-comp .icon-overlay label h3 {
  margin: 0;
  color: #fff;
  font-size: 25px;
  line-height: 0;
  padding-bottom: 4px;
}
.img-upload-comp .upload-icon {
  background: #000;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 30px;
  height: 30px;
}

.img-upload-comp .upload-icon input {
  display: none;
}

.img-upload-comp .delete-icon {
  outline: none;
  border: none;
  background: none;
  font-family: "Nunito Sans", sans-serif;
  border: 1px solid #e0201c;
  padding: 0.6rem 1rem;
  border-radius: 4px;
  min-width: 11.75rem;
  color: #e0201c;
  font-size: 16px;
  cursor: pointer;
  margin-top: 25px;
  transition: background-color 0.3s ease, color 0.3s ease;
  &:hover {
    background-color: #e0201c;
    color: #fff;
  }
}

/* IMAGE GALLERY */
.gallery-page {
  margin-bottom: 100px;
}
.gallery-page .containerr {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.gallery-page .containerr .left {
  flex: 2;
  background-color: #f6fafe;
  padding: 40px;
  border-radius: 10px;
}
.gallery-page .containerr .right {
  flex: 1;
  display: flex;
  justify-content: center;
}
.gallery-page .right img {
  width: 100%;
  object-fit: contain;
  /* height: 550px; */
  position: sticky;
  top: 0px;
}
.wrapper-img-upload-dashed {
  display: flex;
  justify-content: center;
  border-color: #ccc7c7;
  border-style: dashed;
  padding-top: 20px;
  margin-bottom: 20px;
}
/* LINKS PAGE */
.link-page {
  margin-bottom: 100px;
}
.link-page .containerr {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.link-page .containerr .left {
  flex: 2;
  background-color: #f6fafe;
  padding: 40px;
  border-radius: 10px;
}
.link-page .containerr .right {
  flex: 1;
  display: flex;
  justify-content: center;
}
.link-page .right img {
  width: 100%;
  object-fit: contain;
  height: 550px;
  position: sticky;
  top: 0px;
}
.show-link-con {
  border-top: 3px solid #bfbfbf;
  padding: 1rem 0;
}
.show-link-con .trash-con {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.show-link-con .trash-con p {
  margin: 0;
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
}
.show-link-con .trash-con svg {
  color: #e0201c;
  font-size: 40px;
  align-self: flex-end;
  border: 1px solid #e0201c;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
}

.links-container h4 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
  border-bottom: 2px solid #ddd;
  padding-bottom: 0.5rem;
  margin-top: 12px;
}

.links-container ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.links-container li {
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  margin-bottom: 1rem;
  padding: 0.75rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease-in-out;
}

.links-container li:hover {
  background-color: #f0f0f0;
}

.links-container img {
  border-radius: 8px;
  margin-right: 1rem;
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.links-container span {
  font-size: 1rem;
  font-weight: 500;
  margin-right: 1rem;
  color: #555;
}

.links-container a {
  color: #007bff;
  text-decoration: none;
  margin-right: auto;
  transition: color 0.2s ease-in-out;
}

.links-container a:hover {
  color: #0056b3;
}

.links-container button {
  background-color: #e74c3c;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: background-color 0.2s ease-in-out;
}

.links-container button:hover {
  background-color: #c0392b;
}

/* LANDING PAGE */
.landing-page {
  margin-bottom: 100px;
}
.landing-page .containerr {
  display: flex;
  justify-content: space-between;
  /* align-items: start; */
  gap: 30px;
}
.landing-page .containerr .left {
  flex: 2;
  background-color: #f6fafe;
  padding: 40px;
  border-radius: 10px;
}
.landing-page .containerr .right {
  flex: 1;
  display: flex;
  justify-content: center;
}
.landing-page .right img {
  width: 100%;
  object-fit: contain;
  height: 550px;
  position: sticky;
  top: 0px;
}
/* SOCIAL PAGE */
.social-page {
  margin-bottom: 100px;
}
.social-page .containerr {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.social-page .containerr .left {
  flex: 2;
  background-color: #f6fafe;
  padding: 40px;
  border-radius: 10px;
}
.social-page .containerr .right {
  flex: 1;
  display: flex;
  justify-content: center;
}
.social-page .right img {
  width: 100%;
  object-fit: contain;
  height: 550px;
  position: sticky;
  top: 0px;
}
/* VIDEO PAGE */
.video-page {
  margin-bottom: 100px;
}
.video-page .containerr {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.video-page .containerr .left {
  flex: 2;
  background-color: #f6fafe;
  padding: 40px;
  border-radius: 10px;
}
.video-page .containerr .right {
  flex: 1;
  display: flex;
  justify-content: center;
}
.video-page .right img {
  width: 100%;
  object-fit: contain;
  height: 550px;
  position: sticky;
  top: 0px;
}

/* UPLOAD VIDEO */
.video-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding-bottom: 20px;
}

.upload-btn {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.upload-btn:hover {
  background-color: #45a049;
}

.video-info {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  gap: 12px;
  padding-bottom: 20px;
}

.video-thumbnail {
  width: 120px;
  height: 120px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}

.video-details {
  display: flex;
  align-items: center;
  gap: 10px;
}

.video-details span {
  font-size: 16px;
  color: #333;
}

.delete-icon {
  color: red;
  font-size: 20px;
  cursor: pointer;
}

.delete-icon:hover {
  color: darkred;
}

/* BUSINESS PAGE */
.business-page {
  margin-bottom: 100px;
}
.business-page .containerr {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.business-page .containerr .left {
  flex: 2;
  background-color: #f6fafe;
  padding: 40px;
  border-radius: 10px;
}
.business-page .containerr .right {
  flex: 1;
  display: flex;
  justify-content: center;
}
.business-page .right img {
  width: 100%;
  object-fit: contain;
  height: 550px;
  position: sticky;
  top: 0px;
}

/* FACILITES COMP */

.facilities-comp {
  background-color: #f2f2f2;
  padding: 1rem;
  border-radius: 0.5rem;
}
.facilities-comp .wrapper {
  display: flex;
  padding-left: 0px;
  flex-wrap: wrap;
  list-style: none;
  /* height: 120px; */
  width: 100%;
  /* padding-top: 40px; */
  font-family: "Poppins", sans-serif;
  justify-content: center;
}

.facilities-comp .wrapper .icon {
  position: relative;
  background: #fff;
  border-radius: 12px;
  margin: 5px;
  width: 50px;
  height: 50px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1); */
  cursor: pointer;
  background-color: #fcfcfc;
  border: 1px solid #d9d9d9;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.facilities-comp .wrapper .icon.active {
  border: 2px solid #1da1f2;
}
.facilities-comp .wrapper .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background: #1da1f2;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  /* box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1); */
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.facilities-comp .wrapper .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #1da1f2;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.facilities-comp .wrapper .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.facilities-comp .wrapper .icon:hover span,
.facilities-comp .wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* TIME INPUT COMPONENT */

.time-input-component {
}

.time-format-toggle {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.time-format-toggle button {
  padding: 10px 20px;
  border: 1px solid #ccc;
  background-color: white;
  cursor: pointer;
}

.time-format-toggle button.active {
  background-color: #007bff;
  color: white;
}

.time-format-toggle button + button {
  margin-left: 10px;
}

.days-time-wrapper {
  display: flex;
  flex-direction: column;
}

.day-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
}

.day-row label {
  display: flex;
  gap: 10px;
  align-self: flex-start;
  margin-bottom: 10px;
  min-width: 120px;
}

.time-slots-container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 120px);
}

.time-slot {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}

.time-slot .time-slot-wrap {
  display: flex;
  gap: 20px;
  align-items: center;
  width: 100%;
}

.time-slot input[type="time"] {
  padding: 5px;
  margin-right: 10px;
  width: 100%;
}

.add-btn,
.remove-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #007bff;
  font-size: 20px;
  margin-left: 10px;
}

.add-btn:hover,
.remove-btn:hover {
  color: #0056b3;
}

/* EVENT PAGE */
.event-page {
  margin-bottom: 100px;
}
.event-page .containerr {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.event-page .containerr .left {
  flex: 2;
  background-color: #f6fafe;
  padding: 40px;
  border-radius: 10px;
}
.event-page .containerr .right {
  flex: 1;
  display: flex;
  justify-content: center;
}
.event-page .right img {
  width: 100%;
  object-fit: contain;
  height: 550px;
  position: sticky;
  top: 0px;
}

/* EVENT SCHEDULE COMP */
.eventScheduleComp {
}
.eventScheduleComp .top {
  display: flex;
  align-items: center;
  background-color: #f6fafe;
  margin-bottom: 1.5rem;
  padding: 1rem 0;
  gap: 12px;
  padding-left: 20px;
}
.eventScheduleComp .top p {
  margin: 0;
  font-size: 20px;
  font-family: "Nunito Sans", sans-serif;
  padding: 6px 20px;
  border-radius: 6px;
  border: 1px solid #307fe2;
  color: #307fe2;
  min-width: 140px;
  text-align: center;
  cursor: pointer;
}
.time-format-option {
  cursor: pointer;
  padding: 5px 10px;
}

.time-format-option.active {
  background-color: #007bff;
  color: #fff !important;
  border-radius: 4px;
}

.eventScheduleComp .bottom {
}
.eventScheduleComp .bottom .input-con {
  display: flex;
  margin-bottom: 12px;
  align-items: start;
  gap: 20px;
}
.eventScheduleComp .bottom .input-con input {
  align-self: flex-end;
  padding: 8px 12px;
  border-radius: 4px;
  border: none;
  outline: none;
  border: 1px solid #bfbfbf;
}
.eventScheduleComp .bottom .input-con .input-wrap {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}
.eventScheduleComp .bottom .input-con .input-wrap label {
  color: #262626;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
}
.eventScheduleComp .bottom .input-con .input-wrap input {
  width: 100%;
  padding: 8px 12px;
  border-radius: 4px;
  border: none;
  outline: none;
  border: 1px solid #bfbfbf;
}
.eventScheduleComp .bottom .input-con .input::placeholder {
  color: #6d6c6c;
  font-family: "Nunito Sans", sans-serif;
}
.eventScheduleComp .bottom .timezone-con {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 25px;
  margin-bottom: 25px;
}
.eventScheduleComp .bottom .timezone-con .label-allday {
  display: flex;
  gap: 12px;
  align-items: center;
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
  color: #262626;
  min-width: 80px;
}
.eventScheduleComp .bottom .timezone-con .timezone-main {
  display: flex;
  flex-direction: column;
  width: calc(100% - 80px);
}
.eventScheduleComp .bottom .timezone-con .timezone-main label {
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
  color: #262626;
}
.eventScheduleComp .bottom .timezone-con .timezone-main select {
  width: 100%;
  padding: 8px 12px;
  border-radius: 4px;
  border: none;
  outline: none;
  border: 1px solid #bfbfbf;
}
.eventScheduleComp .bottom .allow-guest-txt {
  color: #a6a6a6;
  font-size: 12px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 500;
}

/* PAYMENT CSS */
.payment-page {
  background-color: #f6fafe;
  height: 100%;
}
.payment-page .wrapper {
  max-width: 910px;
  margin-inline: auto;
}
.payment-page .wrapper h1 {
  font-size: 24px;
  font-family: "Nunito Sans", sans-serif;
  color: #000;

  padding: 20px 0px;
  font-weight: 600;
}
.payment-page .containerr {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
}
.payment-page .containerr .left {
  min-width: 535px;
}
.payment-page .containerr .left form {
  background-color: #fcfcfc;
  box-shadow: 0 5px 8px 0 rgba(89, 89, 89, 0.18);
  border-radius: 6px;
  padding: 1.5rem;
  min-height: 615px;
}
.payment-page .containerr .left form .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 16px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 25px;
}
.payment-page .containerr .left form .top .content {
  display: flex;
  align-items: center;
  gap: 12px;
}
.payment-page .containerr .left form .top .content h2 {
  font-size: 24px;
  font-family: "Nunito Sans", sans-serif;
  color: #000;
  margin: 0;
  font-weight: 600;
}
.payment-page .containerr .left form .top .content p {
  font-size: 18px;
  font-family: "Nunito Sans", sans-serif;
  color: #000;
  margin: 0;
  font-weight: 500;
}
.payment-page .containerr .left form .top .payment-card {
  display: flex;
  align-items: center;
  gap: 5px;
}
.payment-page .containerr .left form .top .payment-card img {
  max-width: 2rem;
  margin-left: 0px;
}
.payment-page .containerr .left form .stripe-card-input .pay_input {
  color: #1f2022;
  padding: 10px;
  position: relative;
  font-size: 16px;

  border: 1px solid #bfbfbf;
  position: relative;
  border-radius: 4px;
  background-color: white;
  width: 100%;
  height: 46px;
  outline: none;
}
.payment-page .containerr .left form .stripe-card-input label {
  color: #262626;
  font-family: poppins, sans-serif;
  font-size: 16px;
}
.payment-page .containerr .left form .stripe-card-input .btn-stripe {
  width: 100%;
  background-color: #e0201c;
  border: none;
  color: #fff;
  font-size: 18px;
  font-family: poppins, sans-serif;
  height: 50px;
  border-radius: 4px;
  margin-bottom: 40px;
}
.payment-page .containerr .left form .terms {
  margin: 0;
  font-size: 12px;
  font-family: "Nunito Sans", sans-serif;
  color: #000000;
  text-align: center;
}
.payment-page .containerr .left form .terms span {
  color: #e0201c;
  cursor: pointer;
}
.payment-page .containerr .right {
  max-width: 350px;
}
.payment-page .containerr .right .top {
  padding: 1rem 1.5rem;
  border: 1px solid #e0201c;
  border-radius: 6px;
  margin-bottom: 16px;
}
.payment-page .containerr .right .top h3 {
  color: #000;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
}
.payment-page .containerr .right .top p {
  color: #000;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}
.payment-page .containerr .right .payment-certificate {
  padding: 1rem 1.5rem;
}
.payment-page .containerr .right .payment-certificate h3 {
  font-size: 18px;
  font-family: "Nunito Sans", sans-serif;
  color: #000;
  margin-bottom: 16px;
}
.payment-page .containerr .right .payment-certificate p {
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
  color: #000;
  margin-bottom: 16px;
}
.payment-page .containerr .right .payment-certificate .payment-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.payment-page .containerr .right .payment-certificate .payment-logo img {
  width: 3.5rem;
  margin-right: 1rem;
}

/* subscriptionPopup */
.subscriptionPopup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #facf51;
  padding: 8px 24px;
  border-radius: 0.5rem;
  margin-top: 16px;
  margin-bottom: 16px;
}
.subscriptionPopup .left {
  display: flex;
  align-items: center;
  gap: 8px;
}
.subscriptionPopup p {
  color: #000;
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
  margin: 0;
}
.subscriptionPopup svg {
  font-size: 24px;
  color: #000;
}

.subscriptionPopup button {
  background-color: #fff;
  color: #e0201c;
  border: 1px solid #e0201c;
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
  padding: 5px 14px;
  border-radius: 0.5rem;
}

/* SCAN REDIRECT CSS */

/* .table-wrapper {
  margin: 20px auto;
  width: 80%;
  max-width: 800px;
}

.content-table-scan {
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;
}

.content-table-scan th,
.content-table-scan td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.content-table-scan th {
  background-color: #f4f4f4;
  font-weight: bold;
  text-transform: uppercase;
}

.content-table-scan tr:nth-child(even) {
  background-color: #f9f9f9;
}

.content-table-scan tr:hover {
  background-color: #f1f1f1;
}

.content-table-scan td {
  color: #333;
} */

/* QR DETAIL ANALYTCIS EACH USER CSS */

/* QRDETAIL */
.qrDetailsPage {
  padding-inline: 80px;
}
.qrDetailsPage .userAnalyticscon {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;
  margin-top: 40px;
}
.qrDetailsPage .userAnalyticscon .cardd {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 8px 0 #5959592e;
  padding: 20px;
  width: calc(50% - 10px);
  text-align: center;
  text-align: center;
  border: 1px solid #f3ecec;
}
.qrDetailsPage .userAnalyticscon .cardd svg {
  font-size: 24px;
  color: #e0201c;
  margin-bottom: 10px;
}
.qrDetailsPage .userAnalyticscon .cardd h3 {
  margin: 10px 0;
  color: #333;
  font-size: 20px;
  font-weight: 700;
  font-family: poppins, sans-serif;
}
.qrDetailsPage .userAnalyticscon .cardd p {
  font-size: 18px;
  color: #555;
  font-family: poppins, sans-serif;
  font-weight: 500;
}
.qrDetailsPage .all-card-con {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  gap: 30px;
  margin-bottom: 40px;
}
.qrDetailsPage .all-card-con .cardd p {
  color: #000;
  font-family: Nunito Sans, sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.qrDetailsPage .all-card-con .cardd {
  background-color: #fcfcfc;
  border-radius: 0.5rem;
  box-shadow: 0 5px 8px 0 #5959592e;
  height: 350px;
  padding: 1.5rem;
  flex: 1;
}
.qrDetailsPage .graph-con {
  background-color: #fcfcfc;
  border-radius: 0.5rem;
  box-shadow: 0 5px 8px 0 #5959592e;
  height: 350px;
  padding: 1.5rem;
  flex: 1;
  margin-bottom: 40px;
}
.qrDetailsPage .graph-con p {
  color: #000;
  font-family: Nunito Sans, sans-serif;
  font-size: 18px;
  font-weight: 600;
}
.qrDetailsPage .stats-txt {
  color: #000;
  font-family: Nunito Sans, sans-serif;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

/* Container Styles */
.qrDetailsPage .toggle-con {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
  text-align: center;
  border: 1px solid #f3ecec;
  margin: auto;
  transition: all 0.3s ease-in-out;
  max-width: 600px;
  margin-bottom: 40px;
}

/* Hover effect for card container */
.qrDetailsPage .toggle-con:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

/* Tab Buttons */
.qrDetailsPage .tab-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.qrDetailsPage .tab-button {
  background-color: transparent;
  border: 2px solid #f3ecec;
  border-radius: 8px;
  color: #333;
  padding: 12px 25px;
  margin: 0 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
  position: relative;
}

.qrDetailsPage .tab-button.active {
  background-color: #e0201c;
  color: #fff;
  border-color: #e0201c;
}

.qrDetailsPage .tab-button:not(.active):hover {
  background-color: #f9f9f9;
  border-color: #ddd;
}

.qrDetailsPage .tab-button.active::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* NO QR FOUND */
.no-qr-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px;
  /* background-color: #f9f9f9; */
  border-radius: 12px;
  /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); */
}

.no-qr-image {
  max-width: 200px;
  margin-bottom: 20px;
  border-radius: 200px;
}

.no-qr-found h2 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.no-qr-found p {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
  max-width: 768px;
}

.create-qr-btn {
  border: none;
  outline: none;
  background: #e0201c;
  padding: 0.7rem 1rem;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  color: #fff;
  border-radius: 4px;
  font-weight: 600;
  height: 50px;
  display: flex;
  justify-content: center;
  gap: 6px;
  align-items: center;
  width: 280px;
}
.create-qr-btn a {
  text-decoration: none;
  color: #fff;
}
.create-qr-btn:hover {
  background-color: #e0201c;
}

.qr-preview__header__buttons-toggle {
  background: #f2f2f2;
  display: flex;
  padding: 7px 8px;
  border-radius: 6.25rem;

  margin-inline: auto;
}
.button-toggle.button--secondary {
  color: #fcfcfc;
  display: flex;
  justify-content: center;
  border: none;
  outline: none;
  font: inherit;
  padding: 4px 24px;
  border-radius: 100px;
  font-size: 14px;
  font-family: "Nunito Sans", sans-serif;
}
.button-toggle .button__text {
  font-weight: 700 !important;
  font-size: 14px !important;
}
.button-toggle.button--deactivated.button--secondary {
  background-color: transparent;
  display: flex;
  justify-content: center;
  border: none;
  outline: none;
  font: inherit;
  padding: 4px 24px;
  border-radius: 100px;
  font-size: 14px;
  font-family: "Nunito Sans", sans-serif;
}
.qr-preview__header__buttons-toggle .button--active {
  background-color: #e0201c;
}

.qr-preview__header__buttons-toggle .button--inactive {
  background-color: transparent;
  color: #bfbfbf !important;
}
.qr-editor-detail .containerr .right {
  flex: 1 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  position: sticky;
  height: 620px;
  top: 0px;
}

/* PREVIEW STYLING */
/* .qrpreview-con{
  position: relative;
  height: 572px;
  width: 287px;
  overflow: hidden;
}
.qrpreview-con .framePreview{

  position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    display: flex;
    z-index: 2;
}
.qrpreview-con .previewHeaderTop{
  position: absolute;
  top: 2px;
  left: 10px;
}
.qrpreview-con .previewWrapper{
  position: absolute;
  top: 80px;
  transform: translateX(50%);
  overflow-y: auto;
} */

.qr-preview__layout__image {
  height: 572px;
  width: 287px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.Preview-layout {
  width: 88%;
  text-align: center;
  border-radius: 2.625rem;
  /* height: 99%; */
  height: 93%;
  position: absolute;
  margin-left: 15px;
  left: 2px;
  background-color: #fff;
}
.preview-frame {
  position: absolute;
  width: 100%;
  height: 103%;
  top: 0;
  left: 0;
  pointer-events: none;
  display: flex;
  z-index: 2;
}
.topHeaderSvg {
  position: relative;
  z-index: 2;
}
.layout-content {
  /* position: absolute; */
  /* height: 86%; */
  height: 99%;
  width: 100%;
  left: 6px;
  overflow-y: auto;
  /* padding-top: 2.875rem; */
  padding-top: 16px;
  padding-bottom: 1.25rem;
  top: 30px;
  font-family: "Nunito Sans", sans-serif;
  padding-left: 6px;
  padding-right: 6px;
}
.layout-content::-webkit-scrollbar {
  width: 8px;
}

.layout-content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.layout-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.layout-content.url {
  overflow: hidden;
  padding: 0px;
  top: -20px;
}

.img-upload-comp-multiple {
  padding: 10px;
  width: 100%;
}

.img-upload-comp-multiple .file-input {
  display: none; /* Hide the default file input */
}
.img-upload-comp-multiple .upload-label {
  text-align: center;
}

.img-upload-comp-multiple .file-input-label {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: 1px solid #007bff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 15px;
  margin-inline: auto;
  width: 280px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.img-upload-comp-multiple .file-input-label:hover {
  background-color: #0056b3;
}

.img-upload-comp-multiple .img-gallery {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.img-upload-comp-multiple .img-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  background-color: #f9f9f9;
}

.img-upload-comp-multiple .uploaded-img {
  max-width: 100px;
  max-height: 100px;
  border-radius: 4px;
}

.img-upload-comp-multiple .delete-btn {
  background: none;
  border: none;
  color: #d9534f;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
}

/* CSS FOR ELABELS BUTTON PRODUCT SELECTION */
.product-selection-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.product-selection-buttons button {
  padding: 10px 20px;
  border: 1px solid #ccc;
  background-color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  border-radius: 6px;
  text-transform: uppercase;
}

.product-selection-buttons button.selected {
  background-color: #e0201c;
  color: white;
  border-color: #e0201c;
  box-shadow: 0 4px 8px rgba(224, 32, 28, 0.3);
}

.product-selection-buttons button:hover {
  background-color: #f8d7da;
  color: #e0201c;
  box-shadow: 0 4px 8px rgba(224, 32, 28, 0.15);
  border-color: #e0201c;
}
/* CSS for disabled buttons */
.product-selection-buttons button:disabled {
  background-color: #f5f5f5;
  border-color: #ccc;
  color: #888;
  cursor: not-allowed;
  opacity: 0.6;
}

.product-selection-buttons button:disabled:hover {
  background-color: #f5f5f5;
  color: #888;
  box-shadow: none;
}

/* ADD REVIEW CSS START */
/* General Styles */
.form-builder-container {
  font-family: "Nunito Sans", sans-serif;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
}

.title,
.preview-title {
  text-align: center;
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.input-field {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 2px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  background-color: #fff;
  transition: border-color 0.3s ease;
}

.input-field:focus {
  border-color: #e0201c;
  outline: none;
}

.button-container {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  margin-bottom: 20px;
}

/* Add Question Button */
.add-question-btn {
  background-color: #e0201c;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-question-btn:hover {
  background-color: #c01817;
}

/* Question Card Styles */
.question-card {
  margin-top: 20px;
  background-color: #fff;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.question-card .input-field {
  margin-top: 10px;
}

/* Select and Option Styles */
select.input-field {
  cursor: pointer;
}

.add-option-btn {
  background-color: #e0201c;
  color: white;
  font-size: 14px;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-option-btn:hover {
  background-color: #c01817;
}

input[type="text"].input-field {
  margin-bottom: 10px;
}

.radio-label,
.checkbox-input {
  margin-bottom: 8px;
  display: block;
}

.checkbox-input {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

/* Delete Question Button */
.delete-question-btn {
  color: #e0201c;
  cursor: pointer;
  font-size: 20px;
  margin-top: 10px;
}

.delete-question-btn:hover {
  color: #c01817;
}

/* Preview Form Section */
.form-preview {
  background-color: #fff;
  padding: 12px;
  border-radius: 6px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.preview-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.question-text {
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.radio-label {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

input[type="radio"],
input[type="checkbox"] {
  margin-right: 10px;
}

/* Submit Button */
.submit-btn {
  background-color: #e0201c;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.submit-btn:hover {
  background-color: #c01817;
}

.submit-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Mobile Responsiveness */
@media (max-width: 600px) {
  .form-builder-container {
    padding: 15px;
  }

  .input-field,
  .add-question-btn,
  .add-option-btn,
  .submit-btn {
    width: 100%;
  }

  .question-card {
    flex-direction: column;
  }
}

/* CUSTOM CHECKBOX STYLE */
.checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  font-size: 16px;
  padding-left: 35px;
  padding-right: 10px;
  user-select: none;
  font-family: "Nunito Sans", sans-serif;
}

.checkbox-label input {
  position: absolute;
  left: -9999px;
}

.checkbox-label input:checked + .checkbox-custom {
  background-color: #e0201c;
  border-color: #e0201c;
}

.checkbox-custom {
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  border-radius: 5px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.checkbox-custom::after {
  content: "";
  position: absolute;
  left: 6px;
  top: 2px;
  width: 10px;
  height: 15px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.checkbox-label input:checked + .checkbox-custom::after {
  opacity: 1;
}

/* ADD REVIEW CSS END */

.back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
  color: inherit;
  padding: 8px 16px;
  transition: color 0.3s ease, transform 0.3s ease;
}

.back-button:hover {
  transform: translateX(-5px);
}

.back-button span {
  margin-left: 8px;
  font-family: "Nunito Sans", sans-serif;
  color: #e0201c;
  font-weight: 700;
}

.back-button svg {
  width: 20px;
  height: 20px;
}

/* BACK BUTTON COMP CSS END */

.input-wrap-comp {
  position: relative;
  margin-bottom: 24px;
}

.input-wrap-comp .input-error {
  border: 1px solid red !important;
}

.input-wrap-comp .error-message {
  color: red;
  font-size: 14px;
  position: absolute;
  font-family: "poppins", sans-serif;
  font-weight: 500;
  bottom: -22px;
  left: 0;
}

.checkbox-group {
  display: flex;
  gap: 10px;
}
.checkbox-group.wifi {
  width: 300px;
  justify-content: end;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.checkbox-group label.wifi {
  flex-direction: row-reverse;
}
.checkbox-group input[type="checkbox"] {
  width: 20px;
  height: 20px;
  accent-color: #e0201c;
  cursor: pointer;
}
.checkbox-group input[type="radio"] {
  width: 16px;
  height: 16px;
  accent-color: #e0201c;
  cursor: pointer;
}
.stages-con.mobile {
  display: none;
}

.viewPreviewbtn {
  color: #fcfcfc;
  display: flex;
  justify-content: center;
  border: none;
  outline: none;
  padding: 10px 22px;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Nunito Sans", sans-serif;
  background-color: #e0201c;
  width: 80%;
  margin-inline: auto;
  margin-bottom: 20px;
  display: none;
}

/* ELABELS PREVIEW  */
.beer-container {
  /* background: linear-gradient(135deg, #f4f4f4, #ffffff); */
  background: #fef7f3;
  border: 1px solid #ddd;
  border-radius: 12px;
  max-width: 600px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  margin-inline: auto;
}
.beer-container.ScanPageClass {
  position: relative;
  padding: 0px;
  padding-top: 20px;
  padding-bottom: 10px;
  margin-bottom: 50px;
  left: 0px !important;
}
.beer-container.ScanPageClass .beer-header {
  background-color: transparent;
  padding-bottom: 20px;
}

.beer-container:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
}

/* Header Section */
.beer-container .beer-header {
  /* background: #eee; */
  border-bottom: 1px solid #ddd;
  margin-bottom: 16px;
}

.beer-container .beer-image img {
  display: block;
  width: 100%;
  height: auto;
}

/* Content Section */
.beer-container .beer-content {
  font-family: "Nunito Sans", sans-serif;
  color: #333;
  text-align: left;
  padding: 10px 0px;
}
.beer-container.ScanPageClass .beer-content {
  padding: 20px;
}

.beer-container .beer-product-name {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 16px;
  color: #333;
  word-break: break-all;
}

.beer-container .beer-product-name .icon-beer {
  margin-right: 8px;
  color: #ff9f00;
  font-size: 20px;
}

.beer-container .beer-sku {
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 12px;
  color: #555;
}
.beer-container .beer-sku span {
  word-break: break-all;
}
.beer-container .beer-sku .icon-info {
  margin-right: 8px;
  color: #0073e6;
}

.beer-container .beer-description {
  font-size: 14px;
  color: #666;
  margin-bottom: 16px;
  line-height: 1.6;
}

.beer-container .beer-details {
  margin-bottom: 16px;
}

.beer-container .beer-details p {
  font-size: 14px;
  margin-bottom: 8px;
  color: #444;
}

.beer-container .beer-website {
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 16px;
}

.beer-container .beer-website .icon-globe {
  margin-right: 8px;
  color: #0073e6;
}

.beer-container .beer-website a {
  color: #0073e6;
  text-decoration: none;
  font-weight: bold;
  margin-left: 3px;
}

.beer-container .beer-website a:hover {
  text-decoration: underline;
}

/* Footer Section */
.beer-container .beer-footer {
  background: #f9f9f9;
  border-top: 1px solid #ddd;
  padding-top: 6px;
}

.beer-container.ScanPageClass .beer-footer {
  padding: 10px;
  background-color: transparent;
}
.beer-container .nutrition-image img {
  width: 100%;
  height: auto;
  border-radius: 0 0 12px 12px;
}

/* Hover Effects */
.beer-container a {
  transition: color 0.2s ease;
}

.beer-container a:hover {
  color: #0056b3;
}

/* WINE UI */

/* Responsive Design */
@media (max-width: 768px) {
  .beer-container {
    padding: 15px;
  }

  .beer-product-name {
    font-size: 20px;
  }

  .beer-sku {
    font-size: 14px;
  }

  .beer-description,
  .beer-details p {
    font-size: 12px;
  }
}

/* REVIEW LIST UI CSS */
/* Container for the review list */
.review-list-container {
  max-width: 1200px;
  margin: 30px auto;
  padding: 20px;
  font-family: "Nunito Sans", sans-serif;
  background-color: #fafafa;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

/* Individual review item */
.review-list-container .review-item {
  margin-bottom: 20px;
}

/* Question with list-style-dot */
.review-list-container .review-question {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.review-list-container .list-style-dot {
  font-size: 24px;
  color: #007bff;
  margin-right: 10px;
}

/* Answers list */
.review-list-container .review-answers {
  list-style: none;
  padding: 0;
  margin: 0 0 10px 0;
}

.review-list-container .review-answer {
  font-size: 16px;
  line-height: 1.5;
  padding: 5px 0;
  color: #555;
  border-bottom: 1px dashed #ddd;
}

.review-list-container .review-answer:last-child {
  border-bottom: none;
}

/* Show More button */
.review-list-container .button-container {
  display: flex;
  justify-content: end;
}
.review-list-container .show-more-btn {
  background-color: #e0201c;
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.review-list-container .show-more-btn:hover {
  background-color: #b81917;
}

.review-container {
  max-width: 600px;
  width: 100%;
  margin-bottom: 20px;
  margin-inline: auto;
}
.review-container .accordion-body {
  padding: 16px;
}

/* BUSINESS PAGE CSS FOR CLOCK TIMER START */
/* Styling for the business container */
.business-container-timeclock {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.business-container-timeclock .time-format-selector {
  margin-bottom: 20px;
}

.business-container-timeclock .time-format-label {
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
}

.business-container-timeclock .time-format-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.business-container-timeclock .time-format-button {
  width: 120px;
  color: #e0201c;
  border: 1px solid #e0201c;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
  &:hover {
    color: #e0201c;
    background-color: transparent;
  }
}
.time-format-button.selected {
  background-color: #e0201c !important;
  color: #fff !important;
}

/* Styling for day containers */
.business-container-timeclock .day-container {
  margin-bottom: 20px;
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.business-container-timeclock .day-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.business-container-timeclock .time-fields {
  display: flex;
  gap: 10px;
}

.business-container-timeclock .time-input {
  width: 45%;
}

/* Styling for dialog */
.business-container-timeclock .time-picker-dialog {
  min-width: 400px;
}

.business-container-timeclock .time-picker-dialog .MuiDialogTitle-root {
  font-weight: bold;
}

/* Styling for buttons in the dialog */
.business-container-timeclock .MuiDialogActions-root {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.business-container-timeclock .MuiButton-contained {
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
}

.business-container-timeclock .MuiButton-outlined {
  border-color: #007bff;
  color: #007bff;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
}

.business-container-timeclock .MuiButton-outlined:hover {
  background-color: #007bff;
  color: white;
}

.business-container-timeclock .checkbox-container {
  margin-bottom: 10px;
}

.business-container-timeclock .time-fields {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.business-container-timeclock .time-slot {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

.business-container-timeclock .time-slot-container {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
}

.business-container-timeclock .time-slot-container .MuiTextField-root {
  flex-grow: 1;
}

.business-container-timeclock .add-time-slot {
  margin-top: 6px;
  align-self: flex-start; /* Align the icon to the left */
  border: 1px solid #e0201c;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
  &:hover {
    background-color: #f1a0a0;
    color: white;
  }
}
.business-container-timeclock .add-time-slot svg {
  fill: #e0201c !important;
}
/* BUSINESS PAGE CSS FOR CLOCK TIMER END */
