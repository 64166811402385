@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Oswald:wght@200..700&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Oswald:wght@200..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.error {
  font-family: "poppins", sans-serif;
  font-size: 15px;
  color: red;
  margin-top: 4px;
}

.btn-next .next {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Add some spacing between the spinner and text */
  padding: 10px 20px;
  position: relative;
}

.loading-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.loading-spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.btn-next {
  display: flex;
  align-items: center;
}

.btn-next button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 25px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #fff;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

.error-message {
  color: red;
  font-size: 0.875em;
  display: flex;
  gap: 2px;
  align-items: center;
}

.input-comp-error {
  border: 2px solid red;
}

.error-message-input-comp {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}

/* LOADERRR */
.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}
.loader-wrapper .loaderr {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid #ecbfbe;
  border-right-color: #e0201c;
  animation: l2 1s infinite linear;
}
@keyframes l2 {
  to {
    transform: rotate(1turn);
  }
}

/* 
.skeleton-wrapper {
}


.skeleton-custom {
  background-color: #e0e0e0; 
  border-radius: 4px; 
  animation: skeleton-loading 1.2s infinite linear;
}


.skeleton-custom {
  height: 112px; 
}


.skeleton-custom {
  width: 100%;
}


@keyframes skeleton-loading {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #c0c0c0; 
  }
  100% {
    background-color: #e0e0e0;
  }
} */

.qr-code-card-skeleton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  border-radius: 10px;
  padding: 12px;
  margin-bottom: 20px;
  height: 80px;
  animation: shimmer 1.5s infinite;
  flex-wrap: wrap;
}

.qr-code-card-skeleton .one-con {
  display: flex;
}

.qr-code-card-skeleton .img {
  flex: 0 0 80px;
  height: 65px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  border-radius: 4px;
  border: 1px solid #e0201c;
  animation: shimmer 1.5s infinite;
}

.qr-code-card-skeleton .text-con {
  flex-grow: 1;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.qr-code-card-skeleton .text1 {
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-bottom: 5px;
  height: 10px;
  width: 80px;
}

.qr-code-card-skeleton .two-con,
.qr-code-card-skeleton .three-con {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.qr-code-card-skeleton .two-con .text1,
.qr-code-card-skeleton .three-con .text1 {
  height: 10px;
  width: 200px;
}

.qr-code-card-skeleton .four-con {
  display: flex;
  gap: 10px;
}

.qr-code-card-skeleton .btn {
  background-color: #e0e0e0; /* Light gray background for buttons */
  border-radius: 5px; /* Rounded corners for buttons */
  height: 35px; /* Height for buttons */
  flex: 1; /* Buttons take up equal width */
}

/* Animation for the skeleton loader */
@keyframes pulse {
  0% {
    background-color: #e0e0e0; /* Starting color */
  }
  50% {
    background-color: #d0d0d0; /* Midpoint color */
  }
  100% {
    background-color: #e0e0e0; /* Ending color */
  }
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.swiper {
  width: 240px;
  height: 320px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  height: 200px !important;
}
/* .swiper-carousel .swiper-slide {
  width: 520px !important;
  height: 380px !important;
  border-radius: 8px !important;
  background: #18212b;
  max-width: calc(100% - 48px) ;
} */

.css-eegpc0-MuiTimeClock-root {
  width: auto !important;
}

.css-gqp4xl-MuiPickersLayout-root .MuiPickersLayout-actionBar{
  display: none !important;
}
.css-2x8kvt-MuiPickersArrowSwitcher-root-MuiTimeClock-arrowSwitcher{
  display: none !important;
}
.css-cezg29{
  display: none !important;
}
.css-y2my5h .MuiPickersLayout-actionBar{
  display: none !important;
}

