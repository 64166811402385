@media screen and (max-width: 1200px) {
  .qr-creator-main .page1 .containerr .right {
    display: none;
  }
  .qr-creator-main .page1 .containerr .left {
    padding: 60px 25px;
    padding-top: 95px;
  }
  .bottom-wrapper-stages {
    padding-inline: 25px;
  }
  .stages-con {
    display: none;
  }

  .stages-con.mobile {
    display: flex;
    gap: 25px;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 120px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .qr-editor-detail {
    padding-top: 95px;
    padding-inline: 25px;
  }
  .qr-design {
    padding-top: 95px;
    margin-bottom: 160px;
  }

  .qr-editor-detail .containerr {
    flex-direction: column;
    margin-bottom: 150px;
  }
  .qr-editor-detail .containerr .left {
    padding: 12px !important;
  }
  .qr-editor-detail .top h1 {
    margin-top: 30px;
  }
  .viewPreviewbtn {
    display: block;
    width: 50%;
  }
  .qr-editor-detail .containerr .right {
    display: none !important;
  }
  .qr-creator-main .page1 .containerr .left .card-con {
    flex-direction: column;
  }
  .qr-design {
    padding-inline: 25px;
  }
  .qr-design .containerr .right {
    display: none;
  }
  .qr-design .top h1 {
    margin: 30px 0px 20px 0px;
  }

  .qrCodePreviewMobile {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .qrCodePreviewMobile svg {
    width: 100%;
    height: 300px;
  }
  .qrCodePreviewMobile svg canvas {
    width: 100%;
    height: 100%;
    padding: 2px;
  }
  .previewModal {
    /* display: none; */
  }
}
@media screen and (max-width: 991px) {
  header {
    padding: 12px 40px;
  }
  .landingpage .sec-one {
    padding: 60px 40px;
  }
  .landingpage .sec-two {
    padding: 1.5rem 40px;
  }
  .landingpage .sec-three {
    padding: 60px 40px;
  }
  .landingpage .sec-four {
    padding: 60px 40px;
  }
  .landingpage .sec-five {
    padding: 60px 40px;
  }
  .landingpage .sec-six {
    padding: 60px 40px;
  }
  .landingpage .sec-seven {
    padding: 60px 40px;
  }
  .landingpage .sec-eight {
    padding: 60px 40px;
  }
  footer .top {
    padding: 40px;
    gap: 20px;
  }
  footer .bottom {
    padding: 20px 40px;
  }
  .landingpage .sec-one .containerr {
    flex-direction: column;
  }
  .landingpage .sec-four .containerr {
    flex-direction: column;
  }
  .landingpage .sec-four .containerr .right {
    padding-left: 0px;
  }
  .landingpage .preview-container {
    flex-direction: column;
  }
  .qr-code-card-skeleton {
    height: auto;
    gap: 8px;
  }
  .qrDetailsPage {
    padding-inline: 40px;
  }
  .pricing .content {
    padding: 40px;
    flex-direction: column;
    gap: 20px;
  }
  .pricing .content button {
    width: 100%;
  }
  .pricing .card-container {
    padding: 40px;
  }
  .payment-page .containerr {
    flex-direction: column;
    padding-inline: 25px;
  }
  .payment-page {
    padding-bottom: 30px;
  }
  .payment-page .wrapper h1 {
    text-align: center;
    padding-inline: 25px;
  }
  .payment-page .containerr .left {
    min-width: 0px;
  }
  .payment-page .containerr .right {
    width: 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .userDashboard .content-page {
    margin-left: 0;
    margin-top: 20px;
  }
  .css-wf16b5 {
    background-color: #051a2e !important;
  }
  .css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    background-color: #051a2e !important;
  }
  .css-1wi9kp8 {
    background-color: #051a2e !important;
    padding-top: 80px !important;
    margin-bottom: 40px !important;
  }
  .css-l3frgm {
    margin-left: 0px !important;
  }
  .css-73x0r9 {
    margin-left: 0px !important;
  }

  header {
    padding: 12px 25px;
  }
  .landingpage .sec-one {
    padding: 30px 25px;
  }
  .landingpage .sec-three {
    padding: 30px 25px;
  }
  .landingpage .sec-four {
    padding: 30px 25px;
  }
  .landingpage .sec-five {
    padding: 30px 25px;
  }
  .landingpage .sec-six {
    padding: 30px 25px;
  }
  .landingpage .sec-seven {
    padding: 30px 25px;
  }
  .landingpage .sec-eight {
    padding: 30px 25px;
  }
  footer .top {
    padding: 25px;
    gap: 20px;
  }
  footer .bottom {
    padding: 20px 25px;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;
  }
  footer .bottom p {
    text-align: center;
    font-size: 14px;
  }
  .landingpage .sec-two {
    padding: 1.5rem 25px;
  }

  .landingpage .sec-six .containerr {
    flex-direction: column;
  }
  .landingpage .sec-six .containerr .right {
    flex-wrap: wrap;
    width: 100%;
    justify-content: start;
  }
  .landingpage .sec-six .containerr .right .one {
    width: 100%;
  }
  .landingpage .sec-six .containerr .right .two {
    width: 100%;
  }
  .landingpage .sec-six .containerr .right .cardd {
    max-width: none;
  }
  .landingpage .sec-six .containerr .right .two .cardd {
    margin-top: 0px;
  }
  .landingpage .preview-container .right .wrap {
    gap: 20px;
    flex-wrap: wrap;
  }
  .landingpage .sec-three .steps-con {
    gap: 20px;
  }
  .landingpage .sec-three .steps-con .steps {
    gap: 12px;
  }
  .faq .bottom {
    padding: 40px 25px;
  }
  footer .top {
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }
  footer .top .right {
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 20px;
  }
  footer .top .left img {
    width: 55%;
  }
  footer .top .right .one h1 {
    margin-bottom: 12px;
  }
  .landingpage .preview-container .right .wrap .right .content {
    padding: 12px;
  }
  footer .top .right .one .wrap {
    text-align: start;
  }
  header .auth-con.desktop {
    display: none;
  }

  /* HEADER CSS START */
  header .hamburger-mobile {
    display: block;
  }
  header .mobile-nav.active {
    transform: translate(0);
  }

  header .mobile-nav {
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #fff;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
    transform: translate(100%);
    transition: transform 0.5s linear;
    padding: 20px;
  }
  header .mobile-nav .one {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  header .mobile-nav .two {
    margin-top: 36px;
    height: calc(100vh - 93.64px);
    display: flex;
    justify-content: center;
    align-items: start;
  }
  header .auth-con {
    flex-direction: column;
    gap: 25px;
    width: 100%;
  }
  header .auth-con button.login {
    font-family: "Nunito Sans", sans-serif;
    border: 1px solid #e0201c;
    padding: 0.6rem 1rem;
    border-radius: 4px;
    min-width: 11.75rem;
    color: #e0201c;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    width: 100%;
  }
  header .auth-con button.signup {
    width: 100%;
  }
  header .my-account-btn {
    width: 100%;
  }
  /* HEADER CSS END */
  .qr-main-page .top {
    padding-left: 0px;
    padding-right: 0px;
  }
  .qr-main-page .qrFilterCon .containerr {
    flex-direction: column-reverse;
    align-items: start;
    gap: 20px;
  }
  .qr-main-page .qrFilterCon .containerr .right {
    width: 100%;
  }
  .qr-main-page .qrFilterCon .containerr .sortByDropdown {
    width: 100%;
  }
  .qr-main-page .qrFilterCon .cleanFilter h6 {
    display: none;
  }
  .qr-main-page .qrFilterCon .containerr .left {
    width: 100%;
  }
  .qr-main-page .qrFilterCon .containerr .qrTypeDropdown {
    width: calc(100% - 22px);
  }

  .qr-code-card-skeleton .two-con .text1,
  .qr-code-card-skeleton .three-con .text1 {
    width: 80px;
  }
  .qr-code-card-skeleton .two-con,
  .qr-code-card-skeleton .three-con {
    gap: 0px;
  }

  /* QRDETAIL PAGE */
  .qrDetailsPage {
    padding-inline: 25px;
  }

  .who-we-are .heading {
    padding: 25px;
  }
  .who-we-are .content {
    padding: 25px;
  }
  .who-we-are .content .wrap {
    padding: 12px;
  }
  .contact .top {
    padding: 25px;
  }
  .contact .bottom {
    padding: 25px;
  }
  .contact .bottom .form {
    padding: 16px;
  }
  .faq .top {
    padding: 25px;
  }

  /* ANALYTICS PAGE */
  .qrAnalytics .bottom {
    padding: 12px;
  }
  .qrAnalytics .bottom .all-card-con {
    grid-template-columns: 1fr;
  }

  .qrAnalytics .bottom .all-card-con .cardd:last-child {
    grid-column: span 1;
    width: 100%;
  }
  .qrAnalytics .bottom .period-con {
    width: 100%;
  }
  .qrAnalytics .bottom .period-con .wrap {
    width: 100%;
  }
  .qrAnalytics .bottom .period-con .export-data {
    width: 100%;
  }
  .download-csv-btn {
    width: 100%;
    text-align: center;
  }
  .qrAnalytics .bottom .analytics-con .cardd h3 {
    margin: 0;
    text-align: center;
  }

  .qrAnalytics .bottom .analytics-con .cardd .wrap {
    justify-content: center;
  }
  .qrAnalytics .bottom .analytics-con .cardd .wrap p {
    text-align: center;
  }
  .qrAnalytics .recharts-wrapper {
    width: 100% !important;
  }

  .EditContactInfo .form .input-wrap.last {
    width: 100% !important;
  }

  /* PRICING CSS */
  .pricing .card-container {
    padding: 40px;
  }
  .pricing .card-container {
    padding: 25px;
  }
  .pricing .card-container {
    flex-direction: column;
    gap: 25px;
  }
  .pricing .card-container .cardd:nth-child(2) {
    top: 40px;
    margin-bottom: 40px;
  }
  .payment-page .containerr .left form .top {
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }
  .payment-page .containerr .left form {
    padding: 16px;
    min-height: 0px;
    height: auto;
  }
  .payment-page .containerr .right .payment-certificate {
    padding-left: 0px;
    padding-right: 0px;
  }

  .qr-creator-main .page1 .containerr {
    margin-bottom: 90px;
  }
  .qr-creator-main .page1 .containerr .left .h1 {
    margin-top: 30px;
  }
  .stages-con .select-qr .wrap {
    flex-direction: column;
    align-items: center;
  }
  .viewPreviewbtn {
    width: 60%;
  }
  .ThemePicker__label--first {
    margin-bottom: 8px;
  }
  /* COLOR PICKER */
  .ThemePicker__wrapper {
    gap: 6px;
  }
  .ThemePicker__item {
    width: 16%;
    padding: 0.5rem;
  }
  .ThemePicker__swatch {
    height: 1.5rem;
  }

  .ThemePicker__labels {
    border: 1px solid transparent;
    padding: 0.75rem 0 0;
    flex-basis: content;
    display: flex;
    flex-wrap: wrap;
    margin-right: 1.5rem;
    flex-direction: column;
  }
  .wrap-inp-cmp {
    flex-direction: column;
    padding-bottom: 0px;
    gap: 0px;
  }

  .qr-design .two .qr-frames-con .img-con {
    flex-basis: calc(100% / 3 - 12px);
  }
  .color-picker-con {
    flex-direction: column;
    gap: 6px;
  }
  .social-con-comp.app-social .wrapper {
    flex-direction: column;
    align-items: center;
  }
  .links-container li {
    flex-wrap: wrap;
    gap: 8px;
  }
  .input-wrap-comp .error-message {
    font-size: 12px;
    /* bottom: -37px; */
  }
  .youtube-page .error-message {
    bottom: -37px;
  }
  .input-box-wrapper-social.app-social .wrap {
    flex-direction: column;
  }
  .qr-design .three .choose-contrasting-color {
    flex-direction: column;
  }
  .business-container-timeclock {
    padding: 0;
  }
  .business-container-timeclock .day-container {
    background-color: transparent;
    padding: 0;
    box-shadow: none;
    margin-bottom: 12px;
  }
  .business-container-timeclock .day-title {
    margin: 0;
  }
  .business-container-timeclock .checkbox-container {
    margin: 0;
  }
  .landingpage .sec-three h1 {
    font-size: 30px;
  }
  .landingpage .sec-five .top-content h1 {
    font-size: 30px;
  }
  .landingpage .sec-six .containerr .left .content h1 {
    font-size: 30px;
  }
  .landingpage .sec-eight .content h1 {
    font-size: 30px;
  }
}
@media screen and (max-width: 500px) {
  .landingpage .sec-seven h1 {
    font-size: 25px;
  }
  .landingpage .sec-one .containerr .left h1 {
    font-size: 35px;
    line-height: 43px;
    padding-bottom: 0px;
  }
  .landingpage .sec-one .containerr .left p {
    padding-bottom: 12px;
  }
  .stages-con.mobile {
    gap: 12px;
  }
  .stages-con .select-qr {
    gap: 6px;
  }
  .stages-con .select-qr .wrap p {
    font-size: 13px;
  }
  .qr-editor-detail .accordion-item {
    padding: 0px;
  }
  .accordion-button {
    /* padding: 4px; */
  }
  .accordion-body {
    /* padding: 4px; */
  }
  .qr-editor-detail .accordion-item {
    padding: 6px !important;
  }
  .qr-editor-detail .accordion-body {
    padding: 6px;
  }
  .qr-editor-detail .containerr .left {
    padding: 0px !important;
  }
  .qr-editor-detail .accordion-button {
    padding: 12px 8px;
  }
  .layout-content {
    padding-left: 0px;
  }
  .qr-design .two .bottom {
    padding: 6px;
  }
  .beer-container.ScanPageClass {
    width: 90%;
    /* margin-top: 40px; */
  }
  .review-container {
    width: 90%;
  }
  .review-container .accordion-body {
    padding: 0px;
  }
  .review-container .question-card {
    box-shadow: none;
    padding: 0px;
    border: none;
  }
  .review-container .question-card .input-field {
    margin: 0px;
  }
  .review-container .accord-con .accordion-button {
    padding: 10px 0px 20px 0px;
  }
  /* .landingpage .preview-container .left .preview-buttons-con {
    flex-direction: column;
  } */
  /* .landingpage .preview-container .left .preview-buttons-con .one {
    width: 100%;
  } */
  .landingpage .preview-container .left .preview-buttons-con .one{
    padding: 6px;
    gap: 6px;
  }
  .landingpage .preview-container .left .preview-buttons-con .one p{
    font-size: 14px;
  }
 .logo_heading{
    font-size: 30px;
  }
  
}
@media screen and (max-width: 400px) {
  .qr-main-page .bottom .all-qrCode-con .result-cardd {
    padding: 12px;
    flex-direction: column;
    align-items: start;
  }
  .qr-main-page .bottom .all-qrCode-con .result-cardd .four {
    flex-wrap: wrap;
    width: 100%;
    justify-content: end;
  }
  .bottom-wrapper-stages {
    flex-direction: column-reverse;
    gap: 12px;
    padding-inline: 18px;
  }

  .bottom-wrapper-stages .btn-next button.next {
    width: 100%;
  }
  .bottom-wrapper-stages .btn-next svg {
    right: 110px;
  }
  .loading-content {
    gap: 20px;
    justify-content: center;
  }
  .qr-editor-detail {
    padding-inline: 18px;
  }
  header {
    padding-inline: 18px;
  }
}
@media screen and (max-width: 350px) {
  .stages-con.mobile {
    flex-wrap: wrap;
    gap: 6px;
  }
  .stages-con .select-qr {
    gap: 3px;
  }
  .stages-con .select-qr .wrap p {
    font-size: 12px;
  }
  .qr-design .two .qr-frames-con .img-con {
    flex-basis: calc(100% / 1 - 12px);
  }
}
